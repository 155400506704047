import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropOnCallDashboardChecklist, OCEView, IPropOnCallChecklistService, MeetingEntityForOce } from '../../../store/Checklist';
import { FontAwesomeIconMemo } from '../../../components';
import { Button, Accordion } from 'react-bootstrap';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { FileUpload } from 'primereact/fileupload';
import { ToastId, toast } from 'react-toastify';
import DatePicker, { getDay } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import Iframe from 'react-iframe'

import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    Card, Row, Col, Container, CardBody, Input
} from 'reactstrap';
type OCEViewTableProps = {
    allowDateChange: any;
    allServicesdata: any;
    checklists: any;
    fromDate: any;
    onfromDateChange: any;
    meetingTitle: any;
    ChangedmeetingTitle: any;
    icmMainIncident: any;
    updateIcmMainIncident: any;
    OnQuestionsSave: any;
    isreadonly: any;
    url: any;
    question: any;
    showExpand: boolean;
    Expandall: any;
    ColapseIcons: any;
    ChangeIsExpandedCol: any;
    showIframeDataUrl: any;
    showIframeKustoQuery: any;
    showIncidentsOCEViewModalPopup: any;
    UpdateActionItemOfTable: any;
    IsQuestionChanged: any;
    chkchange: any;
    minDate: any;
    iFrameDataUrl: any;
    iFrameKustoObj: any;
    copyKustoLinkToClipboard: any;
    OpenDetailedQuery: any;
    actionDataTobeSaved: any;
    AddActionItem: any;
    clMeetingitems: any;
    RowSave: any;
    RowEdit: any;
    RowCancel: any;
    UpdateActionItemOfQuestion: any;
    URLOCE: any;
    fileuplaod: any;
    DeleteFile: any;
    downloadFile: any;
    IsQuestionChanges: any;
    LoginuserId: any;
    actionItemTitle: any;
    qualitySpends: any;
    actionItemDescription: any;
    participants: any;
    meetingStatus: any;
    owner: any;
    ActionItemInputChanged: any;
    ActionItemInputChangedEditMode: any;
    fileuplaodValidation: any;
    templateType: any;
    onCallDate: any;
    onCallDateChange: any;
    isAdmin: any;
    checklistDashboardCreatedBy: any;
    errorMsg: any;
    selectedServiceName: string,
    selectedChecklistName: string
};
const OCEViewTable = React.memo<OCEViewTableProps>(({
    allowDateChange,
    allServicesdata,
    checklists,
    fromDate,
    onfromDateChange,
    meetingTitle,
    ChangedmeetingTitle,
    icmMainIncident,
    updateIcmMainIncident,
    OnQuestionsSave,
    isreadonly,
    url,
    question,
    showExpand,
    Expandall,
    ColapseIcons,
    ChangeIsExpandedCol,
    showIframeKustoQuery,
    showIframeDataUrl,
    showIncidentsOCEViewModalPopup,
    UpdateActionItemOfTable,
    IsQuestionChanged,
    chkchange,
    minDate,
    iFrameDataUrl,
    iFrameKustoObj,
    copyKustoLinkToClipboard,
    OpenDetailedQuery,
    actionDataTobeSaved,
    AddActionItem,
    clMeetingitems,
    RowSave,
    RowEdit,
    RowCancel,
    UpdateActionItemOfQuestion,
    URLOCE,
    fileuplaod,
    DeleteFile,
    downloadFile,
    IsQuestionChanges,
    LoginuserId,
    actionItemTitle,
    qualitySpends,
    actionItemDescription,
    participants,
    meetingStatus,
    owner,
    ActionItemInputChanged,
    ActionItemInputChangedEditMode,
    fileuplaodValidation,
    templateType,
    onCallDate,
    onCallDateChange,
    isAdmin,
    checklistDashboardCreatedBy,
    errorMsg,
    selectedServiceName,
    selectedChecklistName
}) => {
    const toastIdRef = useRef<ToastId>('');
    const [collapseJarvisDashboard, setcollapseJarvisDashboard] = useState(false);
    const [collapseDataURL, setcollapseDataURL] = useState(true);
    const [collapseKustoURL, setcollapseKustoURL] = useState(true);
    const ChecklistGuideURL = "https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/16549/Checklist-UserGuide";

    const filterBasedService = (item) => {
        if (item.value === parseInt(url.SId)) {
            return true
        }
        else {
            return false;
        }
    };

    const ExpandCollapseJarvisDashboard = (id) => {
        if (id === "collapseJarvisDashboard") {
            setcollapseJarvisDashboard(!collapseJarvisDashboard);
        }
        else if (id === "collapseDataURL") {
            setcollapseDataURL(!collapseDataURL);
        }
        else if (id === "collapseKustoURL") {
            setcollapseKustoURL(!collapseKustoURL);
        }
        if (document.getElementById(id) !== null) {
            if (document.getElementById(id).classList.contains("show")) {
                document.getElementById(id).classList.value = '';
                document.getElementById(id).classList.add('collapse');
            }
            else {
                document.getElementById(id).classList.value = '';
                document.getElementById(id).classList.add('collapse', 'show');

            }
        }
    }

    const filterBasedChecklist = (item) => {
        if (item.value === parseInt(url.CId)) {
            return true
        }
        else {
            return false;
        }
    };


    const movescrollbar = () => {
        window.scrollTo(0, 0);

    }

    const FuncopenUrlInNewTab = (e, url) => {
        e.stopPropagation();
        openUrlInNewTab(url);
    }

    const FuncOpenDetailedQuery = (e, KustoObj) => {
        e.stopPropagation();
        OpenDetailedQuery(KustoObj);
    }

    const FunccopyTextToClipboard = (e, url) => {
        e.stopPropagation();
        copyTextToClipboard(url);
    }

    //Pop out URL in new tab; for jarvis, kusto and data URLs
    const openUrlInNewTab = (url) => {
        if (url !== '') {
            window.open(url, "_blank");
        }
    }

    const FirstPartOfString = (strLink) => {
        return strLink.substr(0, strLink.indexOf('https')) + " ";
    }


    const MiddlePartOfString = (strLink) => {
        let mPartOfSring = '';
        let fIndex = strLink.indexOf('https');

        if (fIndex !== -1) {
            let fString = strLink.substr(fIndex);
            let paramlIndex = fString.indexOf(')');
            let spaceIndex = fString.indexOf(' ');
            
            if (paramlIndex !== -1) {
                mPartOfSring = fString.substring(0, paramlIndex);
            }
            else if (spaceIndex !== -1) {
                mPartOfSring = fString.substring(0, spaceIndex);
            }
            else {
                mPartOfSring = fString;
            }
            return mPartOfSring
        }
    }

    const LastPartOfString = (strLink) => {
        let lPartOfSring = '';

        let fIndex = strLink.indexOf('https');
        if (fIndex !== -1) {
            let fString = strLink.substr(fIndex);
            let paramlIndex = fString.indexOf(')');
            let spaceIndex = fString.indexOf(' ');

            if (paramlIndex !== -1) {
                lPartOfSring = fString.substr(paramlIndex);
            }
            else if (spaceIndex !== -1) {
                lPartOfSring = fString.substr(spaceIndex);
            }

            return " " + lPartOfSring;
        }
    }

    //Copy URL to clipboard; for jarvis and data URLs
    const copyTextToClipboard = (text) => {
        if (text !== '') {
            var textField = document.createElement('textarea');
            textField.innerText = text;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            if (!toast.isActive(toastIdRef.current)) {
                toastIdRef.current = toast.success(renderToastifyMsg('Link copied to clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            }
        }
    };

    return (
        <div >
            {/*{*/}
            {/*    ((LoginuserId === checklistDashboardCreatedBy) || (isAdmin === false)) ?*/}
                    <React.Fragment>
            <div className='divChecklistFixedHeader'>
                <table className="OCEViewTable" style={{ width: '100%', color: '#148', textAlign: 'center' }}>
                    <tbody>
                        <tr>
                            <td className="checklistheaderstyle" rowSpan={3}>
                                <h3 style={{ color: '#6399cd' }}>CheckList</h3>
                            </td>
                            <td className='tdstyleOCE' style={{ width: templateType === 'Checklist Only' ? '20%' : '15%' }}><label>Service: </label></td>
                            <td className='tdstyleOCE' style={{ width: templateType === 'Checklist Only' ? '20%' : '15%' }}><label>Check List: </label></td>
                            {
                                (templateType === 'Checklist Only') ?
                                    <td className='tdstyleOCE' style={{ width: '20%' }}><label>OnCall Date: </label></td>
                                    : <React.Fragment></React.Fragment>
                            }
                            {
                                (templateType === 'Incident Investigation') ?
                                    <React.Fragment>
                                        <td className='tdstyleOCE' style={{ width: '10%' }}><label>Meeting Date: </label></td>
                                        <td className='tdstyleOCE' style={{ width: '10%' }}><label>Title: </label></td>
                                        <td className='tdstyleOCE' style={{ width: '10%' }}><label>Main Incident ID: </label></td>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                            <td className="checklistheaderstyleButtons" rowSpan={3}>
                                {(isreadonly === false) ?
                                    <Button className='OCEViewbutton' onClick={OnQuestionsSave}>Save</Button>
                                    : <React.Fragment></React.Fragment>
                                }
                                &ensp;&ensp;
                                <Link className='OCEViewbutton btn btn-primary' onClick={movescrollbar} style={{ cursor: 'pointer' }} title='Close'
                                    to={"/OCEList"}>Close
                                </Link> &ensp;&ensp;
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', width: templateType === 'Checklist Only' ? '20%' : '15%' }}>
                                <label><strong className="OCEListLabelstyle">
                                    {/*{isArrayWithLength(allServicesdata) ?*/}
                                    {/*    allServicesdata*/}
                                    {/*        .filter((item) => {*/}
                                    {/*            if (filterBasedService(item)) {*/}
                                    {/*                return true*/}
                                    {/*            }*/}
                                    {/*        })*/}
                                    {/*        .map((row) => (*/}
                                    {/*            row.name*/}
                                    {/*        ))*/}
                                    {/*    : <React.Fragment></React.Fragment>*/}
                                        {/*}*/}
                                        {selectedServiceName}
                                </strong></label>
                            </td>
                            <td style={{ textAlign: 'center', width: templateType === 'Checklist Only' ? '20%' : '15%' }}>
                                <label><strong className="OCEListLabelstyle stylingwordingOCE">
                                    {/*{isArrayWithLength(checklists) ?*/}
                                    {/*    checklists*/}
                                    {/*        .filter((item) => {*/}
                                    {/*            if (filterBasedChecklist(item)) {*/}
                                    {/*                return true*/}
                                    {/*            }*/}
                                    {/*        })*/}
                                    {/*        .map((row) => (*/}
                                    {/*            row.name*/}
                                    {/*        ))*/}
                                    {/*    : <React.Fragment></React.Fragment>*/}
                                        {/*}*/}
                                        {selectedChecklistName}
                                </strong></label>
                            </td>
                            {
                                (templateType === 'Checklist Only') ?
                                    <React.Fragment>
                                        <td style={{  textAlign: 'center', width: '20%' }}>
                                            <Calendar readOnlyInput showIcon className='calendarstyle' value={onCallDate} onChange={(e) => onCallDateChange(e.value)}></Calendar>
                                        </td>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                            {
                                (templateType === 'Incident Investigation') ?
                                    <React.Fragment>
                                        {allowDateChange ?
                                            <td style={{ textAlign: 'left', width: '10%' }}>
                                    <Calendar readOnlyInput showIcon className='calendarstyle' value={fromDate} onChange={(e) => onfromDateChange(e.value)}></Calendar>
                                </td>
                                            :
                                <td style={{ textAlign: 'left', width: '10%' }}>
                                    <label><strong>{moment(fromDate).format("MM/DD/YYYY")}</strong></label>
                                </td>
                                        }
                             <td style={{ textAlign: 'left', width: '10%' }}>
                                <InputText style={{ width: '100%' }} value={meetingTitle} onChange={(e) => ChangedmeetingTitle(e)} />
                            </td>
                            <td style={{ textAlign: 'left', width: '10%' }}>
                                <InputText style={{ width: '100%' }} value={icmMainIncident} onChange={(e) => updateIcmMainIncident(e)} maxLength={9}
                                    onKeyPress={(event) => {
                                        if (! /([0-9])/g.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                        </td>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                      
                      
                                             
                        </tr>
                        <tr style={{ height: '10px' }}>
                            </tr>
                    </tbody>
                </table>    
            </div>
            <div className="row-bottom-margin" style={{ width: '96vw', paddingTop: '87px' }}>
                <Row>
                    <Col xs='7'>
                    </Col>
                    <Col xs='5' style={{textAlign:'right'}}>
                        <React.Fragment><span className='ConfigGuide' onClick={e => openUrlInNewTab(ChecklistGuideURL)}>
                            CheckList User Guide&nbsp;
                            <FontAwesomeIcon style={{ cursor: 'pointer', fontWeight: 'bolder', fontSize: '15px', verticalAlign: 'middle' }} onClick={e => openUrlInNewTab(ChecklistGuideURL)} title='CheckList User Guide' className='icon-format-config' icon={'question-circle'} />
                        </span></React.Fragment>
                    </Col>
                </Row>
                </div>
        
            <div style={{ width: '96vw', paddingTop: '5px' }}>
                {
                    isArrayWithLength(question) ?
                        <React.Fragment>
                            <table className="OCEViewTable" style={{ width: '96vw', color: '#148', textAlign: 'center' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <div style={{ width: templateType === 'Checklist Only' ? '94vw' : '35vw' }}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: templateType === 'Checklist Only' ? '85%' : '75%' }}>
                                                                <span className="oce-title" style={{ textAlign: 'left', paddingLeft: '4px', color: '#6399cd' }}>Questions ({question.length})
                                                        </span>
                                                            </td>
                                                            <td style={{ textAlign: 'right', width: templateType === 'Checklist Only' ? '15%' : '25%', color: '#337ab7', cursor: 'pointer' }}>
                                                                {
                                                                    (showExpand) ?
                                                                        <div id="divExpandID" onClick={e => Expandall()}>
                                                                            <FontAwesomeIconMemo title='Expand All Questions' icon={'plus'} className='icon-format-blue' />
                                                                        </div>
                                                                        : (!(showExpand)) ?

                                                                            <div id="divCollapseID" onClick={e => ColapseIcons()}>
                                                                                <FontAwesomeIconMemo title='Collapse All Questions' icon={'minus'} className='icon-format-blue' />
                                                                            </div>
                                                                            : <React.Fragment></React.Fragment>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {isArrayWithLength(question) ?
                                                    question.map((row, index) => (
                                                        <React.Fragment key={index}>
                                                            <table style={{ paddingTop: '3px', width: '100%' }}>
                                                                <tr>
                                                                    <td style={{ paddingTop: '3px', width: '2%', fontWeight: 'bolder', fontSize: '14px', }}>
                                                                       {index + 1}
                                                                    </td>
                                                                    <td style={{ paddingTop: '3px', width: '98%' }}>
                                                                        <div>
                                                                            <div className="OCEpanel OCEpanel-default" style={{ border: '1px solid grey', borderRadius: '5px' }}>


                                                                                <div className={row.headerClass} id={'head' + index} data-toggle="collapse" data-target={'#collapse' + index} style={{ backgroundColor: row.color, cursor: 'pointer', borderRadius: '2px', width: '100%' }} onClick={e => ChangeIsExpandedCol(index)}>
                                                                                    <Row style={{ margin: '0' }}>

                                                                                        <Col lg="9">
                                                                                            {row.questionName}&ensp;
                                                                                            {
                                                                                                (row.isChanged === true) ?
                                                                                                    <FontAwesomeIconMemo style={{ fontWeight: 'bolder', fontSize: '20px', color: 'limegreen', width: '0.85em' }} icon={'check'} />
                                                                                                    : (row.isChanged === false && row.oceControlValue !== '') ?
                                                                                                        <FontAwesomeIconMemo style={{ fontWeight: 'bolder', fontSize: '20px', color: 'red', width: '0.85em' }} icon={'minus-circle'} />
                                                                                                        : <React.Fragment></React.Fragment>
                                                                                            }
                                                                                        </Col>
                                                                                        <Col lg="2">
                                                                                            {
                                                                                                (row.sourceType === 'Kusto' && !row.isReadonly) ?
                                                                                                    <React.Fragment>
                                                                                                        {
                                                                                                            (templateType === 'Checklist Only') ?
                                                                                                                <React.Fragment>
                                                                                                                    <div style={{ float: 'right' }}>
                                                                                                                        &nbsp;&nbsp;<span className="ocetextunderline" style={{ cursor: 'pointer' }} title="Open Query in Kusto Explorer" onClick={e => FuncOpenDetailedQuery(e, row)}>Open Query in Kusto Explorer</span>&nbsp;
                                                                                            <FontAwesomeIconMemo title="Load this question's Kusto Query" icon={'share-square'} data-target="#collapseKustoURL" onClick={e => FuncOpenDetailedQuery(e, row)} />
                                                                                                                    </div>
                                                                                                                </React.Fragment>
                                                                                                                : <FontAwesomeIconMemo title="Load this question's Kusto Query" icon={'share-square'} data-target="#collapseKustoURL" onClick={e => showIframeKustoQuery(row, e)} />

                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                    : (row.sourceType === 'URL' && !row.isReadonly) ?
                                                                                                        <React.Fragment>
                                                                                                            {(templateType !== 'Checklist Only') ?
                                                                                                                <FontAwesomeIconMemo title="Load this question's URL" icon={'share-square'} data-target="#collapseDataURL" onClick={e => showIframeDataUrl(row.questionUrl.toString(), e)} />
                                                                                                                : <React.Fragment>
                                                                                                                    <div style={{ float: 'right' }}>
                                                                                                                        &nbsp;&nbsp;<span className="ocetextunderline" style={{ cursor: 'pointer', color: 'blue' }} title={ row.questionUrl.toString()} onClick={e => FuncopenUrlInNewTab(e, row.questionUrl.toString())}>Learn more</span>&nbsp;
                                                                                                       </div>
                                                                                                                </React.Fragment>
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                        : <React.Fragment></React.Fragment>
                                                                                            }
                                                                                        </Col>
                                                                                        <Col lg="1" style={{ textAlign: 'right' }}>
                                                                                            {(row.panelclass.indexOf("show") !== -1) ?
                                                                                                <FontAwesomeIcon icon={'chevron-up'} />
                                                                                                : <FontAwesomeIcon icon={'chevron-down'} />}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>

                                                                            <div id={"collapse" + index} className={row.panelclass}>
                                                                                <div className="panel-body">
                                                                                    <Row style={{ padding: '4px' }}>
                                                                                        <Col lg="12">
                                                                                            {
                                                                                                (row.controlType === 'ICMPicker') ?
                                                                                                    <Row>
                                                                                                        <Col lg="12">
                                                                                                            {
                                                                                                                (isreadonly || row.isReadonly) ?
                                                                                                                    <div style={{ cursor: 'pointer', float: 'right', fontWeight: 'bold', textDecoration: 'underline', color: '#07457f' }} title="Select Incidents">
                                                                                                                        Click to Select Incidents
                                                                                                                </div>
                                                                                                                    : <div style={{ cursor: 'pointer', float: 'right', fontWeight: 'bold', textDecoration: 'underline', color: '#07457f' }} title="Select Incidents" onClick={e => showIncidentsOCEViewModalPopup(index)}>
                                                                                                                        Click to Select Incidents
                                                                                                                </div>
                                                                                                            }
                                                                                                        </Col>
                                                                                                        {
                                                                                                            (row.controlType === 'ICMPicker' && row.incidentIds !== '' && row.incidentIds !== undefined) ?
                                                                                                                <Col lg="12">
                                                                                                                    <div style={{ minWidth: '320px', maxWidth: '320px', textAlign: 'left', wordWrap: 'break-word' }}>
                                                                                                                        <b>Incident Id's: </b>
                                                                                                                        {row.incidentIds.split(',').map((selectedIncidentId: any, newindex) => (
                                                                                                                            <React.Fragment key={newindex}>
                                                                                                                                <div style={{ display: 'inline' }}>
                                                                                                                                    <a href={"https://portal.microsofticm.com/imp/v3/incidents/details/" + selectedIncidentId + "/home"} target="_blank">
                                                                                                                                        &nbsp;{selectedIncidentId}
                                                                                                                                    </a>&nbsp;,
                                                                                                                        </div>
                                                                                                                            </React.Fragment>
                                                                                                                        ))
                                                                                                                        }

                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                : <React.Fragment></React.Fragment>
                                                                                                        }
                                                                                                    </Row>
                                                                                                    : (row.controlType === 'ActionItems') ?
                                                                                                        <Row>
                                                                                                            <Col lg="2">
                                                                                                                <div style={{ paddingRight: '0' }}>
                                                                                                                    <span style={{ fontWeight: 'bold' }}>Action Item:</span>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col lg="10">
                                                                                                                <div>
                                                                                                                    <InputText name="ActionItems" style={{ width: '100%' }} readOnly={isreadonly || row.isReadonly} value={row.comment} onChange={(e) => UpdateActionItemOfTable(e, index)} />
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        : (row.controlType === 'TextBox') ?
                                                                                                            <Row>
                                                                                                                <Col lg="12">
                                                                                                                    <Row>
                                                                                                                        <Col lg="12">
                                                                                                                            <InputText style={{ width: '100%' }} readOnly={isreadonly} value={row.oceControlValue} onChange={(e) => IsQuestionChanged(e, 'TextBox', index)} />
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    {
                                                                                                                        (row.isActionRequired === true) ?
                                                                                                                            <Row style={{ paddingTop: '5px' }}>
                                                                                                                                <Col lg="4">
                                                                                                                                    <div style={{ paddingTop: '5px', float: 'right' }}>
                                                                                                                                        <span style={{ fontWeight: 'bold' }}>Action Item:</span>
                                                                                                                                    </div>
                                                                                                                                </Col>
                                                                                                                                <Col lg="8">
                                                                                                                                    <InputText name="ActionItemComment" readOnly={isreadonly} style={{ width: '100%' }} value={row.comment} onChange={(e) => IsQuestionChanged(e, 'TextBox', index)} />
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                            : <React.Fragment></React.Fragment>
                                                                                                                    }
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                            : (row.controlType === 'FileUpload') ?
                                                                                                                <Row style={{ width: '100%' }}>
                                                                                                                    <Col lg="12">
                                                                                                                        <Row>
                                                                                                                            <Col lg="12">
                                                                                                                                <FileUpload name={"FileUpload" + index} maxFileSize={5000000} disabled={isreadonly} onSelect={e => fileuplaodValidation(e, index)} onBeforeUpload={e => fileuplaod(e, index)} />
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row>
                                                                                                                            <Col lg="4">
                                                                                                                                <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>Uploaded File:</span>
                                                                                                                            </Col>
                                                                                                                            <Col lg="8" style={{ cursor: 'pointer' }}>
                                                                                                                                <div title="Download File">
                                                                                                                                    {row.oceControlValue}&nbsp;
                                                                                                                        {(!isreadonly && row.oceControlValue !== '') ?
                                                                                                                                        <React.Fragment>
                                                                                                                                            <FontAwesomeIconMemo style={{ cursor: 'pointer', verticalAlign: 'middle', fontSize: '1.9em', color: '#337ab7' }} title='Download File' icon={'cloud-download-alt'} onClick={e => downloadFile(e, index)} />
                                                                                                                                &nbsp;<FontAwesomeIconMemo style={{ cursor: 'pointer', verticalAlign: 'middle', fontSize: '1.9em', color: 'red', opacity: '0.6' }} title='Delete File' icon={'trash'} onClick={e => DeleteFile(e, index)} />
                                                                                                                                        </React.Fragment>
                                                                                                                                        : <React.Fragment></React.Fragment>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        {
                                                                                                                            (row.isActionRequired === true) ?
                                                                                                                                <Row style={{ paddingTop: '5px', float: 'right' }}>
                                                                                                                                    <Col lg="4">
                                                                                                                                        <div style={{ paddingTop: '5px', float: 'right' }}>
                                                                                                                                            <span style={{ fontWeight: 'bold' }}>Action Item:</span>
                                                                                                                                        </div>
                                                                                                                                    </Col>
                                                                                                                                    <Col lg="8">
                                                                                                                                        <InputText name="ActionItemComment" readOnly={isreadonly} style={{ width: '100%' }} value={row.comment} onChange={(e) => IsQuestionChanges(e)} />
                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                                : <React.Fragment></React.Fragment>
                                                                                                                        }
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                : (row.controlType === 'CheckBox') ?
                                                                                                                    <Row>
                                                                                                                        <Col lg="12">
                                                                                                                            <Row>
                                                                                                                                <Col lg="12">
                                                                                                                                    {row.controlValues.split(',').map((opt: any, newindex) => (
                                                                                                                                        <React.Fragment key={newindex}>
                                                                                                                                            <span>
                                                                                                                                                <input type="checkbox" checked={row.chkvalue[opt] || ''} disabled={isreadonly} onChange={(e) => chkchange(e, index, opt)} name={"chkcolumn" + index} />
                                                                                                                                                &nbsp;{opt.indexOf('https') === -1 ? opt : <span>{FirstPartOfString(opt)}<a href={MiddlePartOfString(opt)} target="_blank" >{MiddlePartOfString(opt)}</a>{LastPartOfString(opt)}</span>}&nbsp;<br />
                                                                                                                                            </span>
                                                                                                                                        </React.Fragment>
                                                                                                                                    ))}
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                            {
                                                                                                                                (row.isActionRequired === true && row.showActionCheckbox === true) ?
                                                                                                                                    <Row style={{ paddingTop: '5px' }}>
                                                                                                                                        <Col lg="4">
                                                                                                                                            <div style={{ paddingTop: '5px', float: 'right' }}>
                                                                                                                                                <span style={{ fontWeight: 'bold' }}>Action Item:</span>
                                                                                                                                            </div>
                                                                                                                                        </Col>
                                                                                                                                        <Col lg="8">
                                                                                                                                            <InputText style={{ width: '100%' }} name={"ActionItemComment"} readOnly={isreadonly} value={row.comment} onChange={(e) => chkchange(e, index, "")} />
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                    : <React.Fragment></React.Fragment>
                                                                                                                            }
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    : (row.controlType === 'Date') ?
                                                                                                                        <Row>
                                                                                                                            <Col lg="12">
                                                                                                                                <Row><Col lg="12">
                                                                                                                                    <Calendar style={{ width: templateType === 'Checklist Only' ? '15%' : '100%' }} readOnlyInput showIcon className='calendarstyle' value={new Date(row.oceControlValue)} onChange={(e) => IsQuestionChanged(e, 'Date', index)}></Calendar>
                                                                                                                                </Col></Row>
                                                                                                                                {
                                                                                                                                    row.isActionRequired === true ?
                                                                                                                                        <Row style={{ paddingTop: '5px' }}>
                                                                                                                                            <Col lg="4">
                                                                                                                                                <div style={{ paddingTop: '5px', float: 'right' }}>
                                                                                                                                                    <span style={{ fontWeight: 'bold' }}>Action Item:</span>
                                                                                                                                                </div>
                                                                                                                                            </Col>
                                                                                                                                            <Col lg="8">
                                                                                                                                                <InputText name={"ActionItemComment"} style={{ width: '100%' }} readOnly={isreadonly} value={row.comment} onChange={(e) => IsQuestionChanged(e, 'Date', index)} />
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                        : <React.Fragment></React.Fragment>

                                                                                                                                }
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        : (row.controlType === 'Dropdown') ?
                                                                                                                            <Row>
                                                                                                                                <Col lg="12">
                                                                                                                                    <Row>
                                                                                                                                        <Col lg="12">
                                                                                                                                            <Dropdown style={{ width: templateType === 'Checklist Only' ? '27%' : '100%' }} className="serevicedropdownchecklist" disabled={isreadonly} value={row.oceControlValue} options={row.controlValues.split(',')} onChange={(e) => IsQuestionChanged(e, 'Dropdown', index)} />
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                    {
                                                                                                                                        (row.isActionRequired === true && row.oceControlValue === row.actionItemValue) ?
                                                                                                                                            <Row style={{ paddingTop: '5px' }}>
                                                                                                                                                <Col lg="4">
                                                                                                                                                    <div style={{ paddingTop: '5px', float: 'right' }}>
                                                                                                                                                        <span style={{ fontWeight: 'bold' }}>Action Item:</span>
                                                                                                                                                    </div>
                                                                                                                                                </Col>
                                                                                                                                                <Col lg="8">
                                                                                                                                                    <InputText name={"ActionItemComment"} style={{ width: '100%' }} readOnly={isreadonly} value={row.comment} onChange={(e) => IsQuestionChanged(e, 'Dropdown', index)} />
                                                                                                                                                </Col>
                                                                                                                                            </Row>
                                                                                                                                            : <React.Fragment></React.Fragment>

                                                                                                                                    }
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                            : (row.controlType === 'TextArea') ?
                                                                                                                                <Row>
                                                                                                                                    <Col lg="12">
                                                                                                                                        <Row>
                                                                                                                                            <Col lg="12">
                                                                                                                                                <InputTextarea style={{ width: '100%' }} rows={2} cols={50} value={row.oceControlValue} readOnly={isreadonly} onChange={(e) => IsQuestionChanged(e, 'TextArea', index)} />
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                        {
                                                                                                                                            (row.isActionRequired === true) ?
                                                                                                                                                <Row style={{ paddingTop: '5px' }}>
                                                                                                                                                    <Col lg="4">
                                                                                                                                                        <div style={{ paddingTop: '5px', float: 'right' }}>
                                                                                                                                                            <span style={{ fontWeight: 'bold' }}>Action Item:</span>
                                                                                                                                                        </div>
                                                                                                                                                    </Col>
                                                                                                                                                    <Col lg="8">
                                                                                                                                                        <InputText name={"ActionItemComment"} style={{ width: '100%' }} readOnly={isreadonly} value={row.comment} onChange={(e) => IsQuestionChanged(e, 'TextArea', index)} />
                                                                                                                                                    </Col>
                                                                                                                                                </Row>
                                                                                                                                                : <React.Fragment></React.Fragment>

                                                                                                                                        }
                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                                : (row.controlType === 'Radiobutton') ?
                                                                                                                                    <Row>
                                                                                                                                        <Col lg="12">
                                                                                                                                            <Row>
                                                                                                                                                <Col lg="12">
                                                                                                                                                    {
                                                                                                                                                        row.controlValues.split(',').map((rbVal, rbindex) => (
                                                                                                                                                            <React.Fragment key={rbindex}>
                                                                                                                                                                <span style={{ fontSize: '16px' }}>
                                                                                                                                                                    <input type="radio" disabled={isreadonly} name={"rdo" + index} value={rbVal} onChange={(e) => IsQuestionChanged(e, 'Radiobutton', index)} checked={row.oceControlValue === rbVal} />&nbsp;{rbVal}&emsp;
                                                                                                                                                    </span>
                                                                                                                                                            </React.Fragment>
                                                                                                                                                        ))
                                                                                                                                                    }
                                                                                                                                                </Col>
                                                                                                                                            </Row>
                                                                                                                                            {
                                                                                                                                                (row.isActionRequired === true && row.oceControlValue == row.actionItemValue) ?
                                                                                                                                                    <Row style={{ paddingTop: '5px' }}>
                                                                                                                                                        <Col lg="4">
                                                                                                                                                            <div style={{ paddingTop: '5px', float: 'right' }}>
                                                                                                                                                                <span style={{ fontWeight: 'bold' }}>Action Item:</span>
                                                                                                                                                            </div>
                                                                                                                                                        </Col>
                                                                                                                                                        <Col lg="8">
                                                                                                                                                            <InputText readOnly={isreadonly} style={{ width: '100%' }} name={"ActionItemComment"} value={row.comment} onChange={(e) => IsQuestionChanged(e, 'Radiobutton', index)} />
                                                                                                                                                        </Col>
                                                                                                                                                    </Row>
                                                                                                                                                    : <React.Fragment></React.Fragment>

                                                                                                                                            }
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                    : <React.Fragment></React.Fragment>
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </table>                                                            
                                                        </React.Fragment>
                                                    ))
                                                    : <React.Fragment></React.Fragment>
                                                }
                                            </div>
                                        </td>
                                        {
                                            (templateType === 'Incident Investigation') ?
                                                <React.Fragment>
                                                    <td style={{ verticalAlign: 'top' }}>
                                                        <div style={{ width: '59vw' }}>

                                                            <div className="OCEpanel OCEpanel-default" style={{ border: '1px solid grey', borderRadius: '5px' }}>
                                                                <div className="OCEpanel-heading" style={{ backgroundColor: 'white', borderRadius: '2px' }}>
                                                                    <Row style={{ margin: '0', fontSize: '16px' }}>
                                                                        <Col lg="3">
                                                                            Jarvis Dashboard
                                                                            {(URLOCE === null || URLOCE === '') ?
                                                                                <React.Fragment></React.Fragment>
                                                                                : <React.Fragment>
                                                                                    &nbsp;&nbsp;<FontAwesomeIcon style={{ cursor: 'pointer' }} title='Open link in New Tab' onClick={e => openUrlInNewTab(URLOCE)} icon={'external-link-alt'} />
                                                                                    &nbsp;&nbsp;<FontAwesomeIconMemo style={{ cursor: 'pointer' }} title='Copy link to Clipboard' onClick={e => copyTextToClipboard(URLOCE)} icon={'paste'} />
                                                                                </React.Fragment>
                                                                            }
                                                                        </Col>
                                                                        <Col lg="9" style={{ cursor: 'pointer' }} onClick={e => ExpandCollapseJarvisDashboard("collapseJarvisDashboard")}>
                                                                            <div className="OCEpanel-heading collapsed" data-toggle="collapse" data-target="#collapseJarvisDashboard">
                                                                                {
                                                                                    collapseJarvisDashboard ?
                                                                                        <FontAwesomeIcon style={{ float: 'right' }} icon={'chevron-up'} title="Click to collapse" />
                                                                                        : <FontAwesomeIcon style={{ float: 'right' }} icon={'chevron-down'} title="Click to expand" />
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div id="collapseJarvisDashboard" className="collapse">
                                                                    <div className="OCEpanel-body">
                                                                        <div style={{ width: '100%' }}>
                                                                            {(URLOCE === null || URLOCE === '') ?
                                                                                <React.Fragment>
                                                                                    &ensp;&ensp;No Data URL Present
                                                                                </React.Fragment>
                                                                                :
                                                                                <Iframe url={URLOCE}
                                                                                    width="100%"
                                                                                    height="600px"
                                                                                    id="JarvisDashboard"
                                                                                    position="relative" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />

                                                            {(iFrameDataUrl !== '') ?
                                                                <div className="OCEpanel OCEpanel-primary" style={{ border: '1px solid grey', borderRadius: '5px', marginTop: '5px' }}>
                                                                    <div className="OCEpanel-heading" style={{ backgroundColor: '#337ab7', borderRadius: '2px' }}>
                                                                        <Row style={{ margin: '0', fontSize: '16px' }}>
                                                                            <Col lg="8">
                                                                                Data URL:
                                                                &nbsp;&nbsp;Open link in New Tab&nbsp;<FontAwesomeIconMemo style={{ cursor: 'pointer' }} title='Open link in New Tab' onClick={e => openUrlInNewTab(iFrameDataUrl)} icon={'external-link-alt'} />
                                                                &nbsp;&nbsp;&nbsp;Copy link to Clipboard&nbsp;<FontAwesomeIconMemo style={{ cursor: 'pointer' }} title='Copy link to Clipboard' onClick={e => copyTextToClipboard(iFrameDataUrl)} icon={'paste'} />
                                                                            </Col>

                                                                            <Col lg="4" style={{ cursor: 'pointer' }} onClick={e => ExpandCollapseJarvisDashboard("collapseDataURL")}>
                                                                                <div className="OCEpanel-heading collapsed" data-toggle="collapse" data-target="#collapseDataURL">
                                                                                    {
                                                                                        collapseDataURL ?
                                                                                            <FontAwesomeIcon style={{ float: 'right' }} icon={'chevron-up'} title="Click to collapse" />
                                                                                            : <FontAwesomeIcon style={{ float: 'right' }} icon={'chevron-down'} title="Click to expand" />
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div id="collapseDataURL" className="collapse show">
                                                                        <div className="OCEpanel-body">
                                                                            <div style={{ width: '100%' }}>
                                                                                {(iFrameDataUrl !== '') ?
                                                                                    <Iframe url={iFrameDataUrl}
                                                                                        width="100%"
                                                                                        height="600px"
                                                                                        id="DataURL"
                                                                                        position="relative" />
                                                                                    : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment></React.Fragment>
                                                            }

                                                            {(Object.keys(iFrameKustoObj).length !== 0) ?
                                                                <div className="OCEpanel OCEpanel-default" style={{ border: '1px solid grey', borderRadius: '5px', marginTop: '5px' }}>
                                                                    <div className="OCEpanel-heading" style={{ backgroundColor: '#337ab7', borderRadius: '2px' }}>
                                                                        <Row style={{ margin: '0', fontSize: '16px' }}>
                                                                            <Col lg="3">
                                                                                Kusto Query
                                                                &nbsp;&nbsp;<FontAwesomeIconMemo style={{ cursor: 'pointer' }} title='Open link in New Tab' onClick={e => OpenDetailedQuery(iFrameKustoObj)} icon={'external-link-alt'} />
                                                                &nbsp;&nbsp;<FontAwesomeIconMemo style={{ cursor: 'pointer' }} title='Copy link to Clipboard' onClick={e => copyKustoLinkToClipboard(iFrameKustoObj)} icon={'paste'} />
                                                                            </Col>
                                                                            <Col lg="9" style={{ cursor: 'pointer' }} onClick={e => ExpandCollapseJarvisDashboard("collapseKustoURL")}>
                                                                                <div className="OCEpanel-heading collapsed" data-toggle="collapse" data-target="#collapseKustoURL">
                                                                                    {
                                                                                        collapseKustoURL ?
                                                                                            <FontAwesomeIcon style={{ float: 'right' }} icon={'chevron-up'} title="Click to collapse" />
                                                                                            : <FontAwesomeIcon style={{ float: 'right' }} icon={'chevron-down'} title="Click to expand" />
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div id="collapseKustoURL" className="collapse show">
                                                                        <div className="OCEpanel-body">
                                                                            <div style={{ width: '100%' }}>
                                                                                {(Object.keys(iFrameKustoObj).length !== 0) ?
                                                                                    <div style={{ paddingLeft: '25px' }}>
                                                                                        <div style={{ cursor: 'pointer', textDecoration: 'underline', color: '#337ab7' }} onClick={e => OpenDetailedQuery(iFrameKustoObj)}>
                                                                                            {iFrameKustoObj.serverName}/{iFrameKustoObj.dbname}
                                                                                        </div>
                                                                                        <br />
                                                                                        <Row>
                                                                                            <Col lg="1">Cluster: </Col>
                                                                                            <Col lg="10">
                                                                                                <FontAwesomeIconMemo title='Copy Kusto Cluster to Clipboard' style={{ cursor: 'pointer', color: '#337ab7', verticalAlign: 'top' }} onClick={e => copyTextToClipboard(iFrameKustoObj.serverName)} icon={'paste'} className="fa-2x" />
                                                                                    &nbsp;&nbsp;
                                                                                    <InputTextarea title="Kusto Cluster" rows={1} cols={80} value={iFrameKustoObj.serverName} disabled />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <br />
                                                                                        <Row>
                                                                                            <Col lg="1">Database: </Col>
                                                                                            <Col lg="10">
                                                                                                <FontAwesomeIconMemo title='Copy Kusto Database to Clipboard' style={{ cursor: 'pointer', color: '#337ab7', verticalAlign: 'top' }} onClick={e => copyTextToClipboard(iFrameKustoObj.dbname)} icon={'paste'} className="fa-2x" />
                                                                                    &nbsp;&nbsp;
                                                                                    <InputTextarea title="Kusto Database" rows={1} cols={80} value={iFrameKustoObj.dbname} disabled />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <br />
                                                                                        <Row>
                                                                                            <Col lg="1">Query: </Col>
                                                                                            <Col lg="10">
                                                                                                <FontAwesomeIconMemo title='Copy Kusto Query to Clipboard' style={{ cursor: 'pointer', color: '#337ab7', verticalAlign: 'top' }} onClick={e => copyTextToClipboard(iFrameKustoObj.query)} icon={'paste'} className="fa-2x" />
                                                                                    &nbsp;&nbsp;
                                                                                    <InputTextarea title="Kusto Query" rows={10} cols={100} value={iFrameKustoObj.query} disabled />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <br />
                                                                                    </div>
                                                                                    : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment></React.Fragment>
                                                            }
                                                            <Row style={{ paddingTop: '10px', paddingBottom: '5px' }}>
                                                                <Col lg="9">
                                                                    <span className="oce-title" style={{ textAlign: 'left', color: '#6399cd' }}>
                                                                        Action Items
                                                                     </span>
                                                                </Col>
                                                                {
                                                                    !isreadonly ?
                                                                        <Col lg="3">
                                                                            <Button disabled={actionDataTobeSaved} className="btninfoAddActionItem" style={{ float: 'right' }} onClick={e => AddActionItem()}>Add Action Item</Button>
                                                                        </Col>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <table id="tablefilter" className="actionItemtableoceview actionitemstablebordered" style={{ maxWidth: '69vw', color: '#148', textAlign: 'center', tableLayout: 'fixed' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: '375px' }} className="thstyleOCEActionItem">Title</th>
                                                                                <th style={{ width: '200px' }} className="thstyleOCEActionItem">Quality Spends</th>
                                                                                <th style={{ width: '200px' }} className="thstyleOCEActionItem">Action Items</th>
                                                                                <th style={{ width: '120px' }} className="thstyleOCEActionItem">Participants</th>
                                                                                <th style={{ width: '150px' }} className="thstyleOCEActionItem">Meeting Status</th>
                                                                                <th style={{ width: '100px' }} className="thstyleOCEActionItem">Owner</th>
                                                                                <th style={{ width: '100px' }} className="thstyleOCEActionItem">Created By</th>
                                                                                <th style={{ width: '100px' }} className="thstyleOCEActionItem">Modified By</th>
                                                                                {isArrayWithLength(clMeetingitems) ?
                                                                                    <th style={{ maxWidth: '100px' }} className="thstyleOCEActionItem"></th>
                                                                                    : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {isArrayWithLength(clMeetingitems) ?
                                                                                clMeetingitems.map((rowMtItm: MeetingEntityForOce, MtItmindex) => (
                                                                                    <React.Fragment key={MtItmindex}>
                                                                                        <tr>
                                                                                            <td className="actionItemstdstyletable" style={{ width: '200px' }}>
                                                                                                {(!rowMtItm.isEdit) ?
                                                                                                    <span>{rowMtItm.title}</span>
                                                                                                    : <div id={"dvTitle" + MtItmindex} style={{ width: '100%' }} >
                                                                                                        <InputText value={rowMtItm.title} style={{ width: '200px' }} name="actionItemTitle" onChange={(e) => ActionItemInputChangedEditMode(e, MtItmindex)} />
                                                                                                    </div>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                                                                {!rowMtItm.isEdit ?
                                                                                                    <span>{rowMtItm.qualitySpends}</span>
                                                                                                    : <div id={"dvQualitySpends" + MtItmindex}>
                                                                                                        <InputText style={{ width: '100%' }} value={rowMtItm.qualitySpends} name="qualitySpends" onChange={(e) => ActionItemInputChangedEditMode(e, MtItmindex)} />
                                                                                                    </div>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                                                                {!rowMtItm.isEdit ?
                                                                                                    <span>{rowMtItm.actionItems}</span>
                                                                                                    : <div id={"dvActionItems" + MtItmindex} style={{ width: '100%' }}>
                                                                                                        <InputText value={rowMtItm.actionItems} style={{ width: '200px' }} name={"actionItems" + MtItmindex} onChange={(e) => UpdateActionItemOfQuestion(e, MtItmindex)} />
                                                                                                    </div>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                                                                {!rowMtItm.isEdit ?
                                                                                                    <span>{rowMtItm.participants}</span>
                                                                                                    : <div id={"dvParticipants" + MtItmindex}>
                                                                                                        <InputText style={{ width: '100%' }} value={rowMtItm.participants} name="participants" onChange={(e) => ActionItemInputChangedEditMode(e, MtItmindex)} />
                                                                                                    </div>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                                                                {!rowMtItm.isEdit ?
                                                                                                    <span>{rowMtItm.meetingStatus}</span>
                                                                                                    : <div id={"dvMeetingStatus" + MtItmindex}>
                                                                                                        <InputText style={{ width: '100%' }} value={rowMtItm.meetingStatus} name="meetingStatus" onChange={(e) => ActionItemInputChangedEditMode(e, MtItmindex)} />
                                                                                                    </div>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                                                                {!rowMtItm.isEdit ?
                                                                                                    <span>{rowMtItm.owner}</span>
                                                                                                    : <div id={"dvOwner" + MtItmindex}>
                                                                                                        <InputText style={{ width: '100%' }} value={rowMtItm.owner} name="owner" onChange={(e) => ActionItemInputChangedEditMode(e, MtItmindex)} />
                                                                                                    </div>
                                                                                                }
                                                                                            </td>
                                                                                            <td style={{ textAlign: 'center' }}>{rowMtItm.createdBy}</td>
                                                                                            <td style={{ textAlign: 'center' }}>{rowMtItm.updatedBy}</td>
                                                                                            <td style={{ textAlign: 'center', whiteSpace: 'nowrap', verticalAlign: 'middle', width: '50px' }}>
                                                                                                {
                                                                                                    (rowMtItm.isEdit && !isreadonly) ?
                                                                                                        <React.Fragment>
                                                                                                            <div style={{ fontSize: 'x-large' }}>
                                                                                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={e => RowSave(MtItmindex)} icon={'save'} title="Save" />&nbsp;
                                                                                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={e => RowCancel(MtItmindex)} icon={'window-close'} title="Cancel" />
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                        : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                                {
                                                                                                    !(rowMtItm.isEdit || isreadonly || actionDataTobeSaved) ?
                                                                                                        <div style={{ fontSize: 'large' }}>
                                                                                                            <FontAwesomeIcon style={{ color: '#3875b2', cursor: 'pointer' }} onClick={e => RowEdit(MtItmindex)} icon={'pencil-alt'} title="Edit" />
                                                                                                        </div>
                                                                                                        : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))
                                                                                : <React.Fragment></React.Fragment>
                                                                            }
                                                                            {
                                                                                actionDataTobeSaved ?
                                                                                    <tr>
                                                                                        <td style={{ textAlign: 'center', width: '287px' }} id="dvTitle">
                                                                                            <InputText style={{ width: '100%' }} name="actionItemTitle" value={actionItemTitle} onChange={(e) => ActionItemInputChanged(e)} />
                                                                                        </td>
                                                                                        <td style={{ textAlign: 'center', width: '100px' }}>
                                                                                            <InputText style={{ width: '100%' }} name="qualitySpends" value={qualitySpends} onChange={(e) => ActionItemInputChanged(e)} />
                                                                                        </td>
                                                                                        <td style={{ textAlign: 'center', width: '100px' }} id="dvActionItems">
                                                                                            <InputText style={{ width: '100%' }} name="actionItemDescription" value={actionItemDescription} onChange={(e) => ActionItemInputChanged(e)} />
                                                                                        </td>
                                                                                        <td style={{ textAlign: 'center', width: '100px' }}>
                                                                                            <InputText style={{ width: '100%' }} name="participants" value={participants} onChange={(e) => ActionItemInputChanged(e)} />
                                                                                        </td>
                                                                                        <td style={{ textAlign: 'center', width: '100px' }}>
                                                                                            <InputText style={{ width: '100%' }} name="meetingStatus" value={meetingStatus} onChange={(e) => ActionItemInputChanged(e)} />
                                                                                        </td>
                                                                                        <td style={{ textAlign: 'center', width: '100px' }}>
                                                                                            <InputText style={{ width: '100%' }} name="owner" value={owner} onChange={(e) => ActionItemInputChanged(e)} />
                                                                                        </td>
                                                                                        <td style={{ textAlign: 'center', width: '100px' }}>{LoginuserId}</td>
                                                                                        <td style={{ textAlign: 'center' }}></td>
                                                                                        <td style={{ textAlign: 'center', whiteSpace: 'nowrap', verticalAlign: 'middle', width: '50px', fontSize: 'x-large' }}>
                                                                                            <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={e => RowSave("-1")} icon={'save'} title="Save" />&nbsp;
                                                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={e => RowCancel("-1")} icon={'window-close'} title="Cancel" />
                                                                                        </td>
                                                                                    </tr>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                            {
                                                                                ((clMeetingitems === null || clMeetingitems.length === 0) && actionDataTobeSaved === false) ?
                                                                                    <tr>
                                                                                        <td colSpan={9}>
                                                                                            No Records Found
                                                                    </td>
                                                                                    </tr>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                        </div>
                                                    </td>
                                                </React.Fragment>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </React.Fragment>
                        : <React.Fragment></React.Fragment>
                }
            </div>
            <br/>
            <div>
                <table style={{ paddingTop: '10px', width: '100%' }}>
                    <tr>
                        <td style={{ paddingTop: '3px', width: '88%' }}>
                        </td>
                        <td style={{  paddingBottom: '10px', paddingTop: '3px', width: '5%'}}>
                            {(isreadonly === false) ?
                                <Button className='OCEViewbutton' onClick={OnQuestionsSave}>Save</Button>
                                : <React.Fragment></React.Fragment>
                            }
                        </td>
                        <td style={{ paddingBottom: '10px', paddingTop: '3px', width: '5%' }}>                          
                            <Link className='OCEViewbutton btn btn-primary' onClick={movescrollbar} style={{ cursor: 'pointer' }} title='Close'
                                to={"/OCEList"}>Close
                            </Link>&ensp;&ensp;
                        </td>
                       
                        </tr>
                    </table>
                        </div>
                    </React.Fragment>
            {/*        : <React.Fragment>*/}
            {/*            <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br />{errorMsg}</div>*/}
            {/*        </React.Fragment>*/}
            {/*}*/}

        </div>
    )
});

OCEViewTable.displayName = 'OCEViewTable';
export default OCEViewTable;
import { MSERHeatmapPreviewAPI, CommonAPI, ChecklistAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import {
    ActionType, IPropOnCallDashboardChecklist, PropOnCallDashboardService,
    IPropOnCallChecklistService, OCEView, MeetingEntityForOce, ChecklistQuestionsForOce, ChecklistQuestionsByChoiceForOce, Incidents
} from './types';
import { IPropOnCallDashboardTemplate, IPropOnCallDashboardQuestionnaire } from './../checklistTemplate/types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import moment from 'moment';
import IdleTimer from 'react-idle-timer';
import { toast } from "react-toastify";
import Axios from 'axios';
import { saveAs } from 'file-saver';

const questioncontroltypeoptions = [{ name: 'Select Control Type', value: '' },
{ name: 'CheckBox', value: 'CheckBox' },
{ name: 'Date', value: 'Date' },
{ name: 'Dropdown', value: 'Dropdown' },
{ name: 'FileUpload', value: 'FileUpload' },
{ name: 'Radiobutton', value: 'Radiobutton' },
{ name: 'TextArea', value: 'TextArea' },
{ name: 'TextBox', value: 'TextBox' }
];
const questioncontroltypedependsonoptions = [{ name: 'Select Control Type', value: '' },
{ name: 'Incident Loader', value: 'ICMPicker' },
{ name: 'Action Items', value: 'ActionItems' }
];
const sourcetypes = [{ name: 'Select Source Type', value: '' },
{ name: 'Kusto', value: 'Kusto' },
{ name: 'URL', value: 'URL' },
{ name: 'None', value: 'None' }
];
const dynamicValidationTypes = [{ name: 'Select Validation Type', value: '' },
{ name: 'On-Call Certification', value: 'OnCallCertification' },
{ name: 'Wiki Updated Date', value: 'WikiUpdatedDate' },
{ name: 'None', value: 'None' }
];
const templateTypes = [{ name: 'Checklist Only', value: 'Checklist Only' },
{ name: 'Incident Investigation', value: 'Incident Investigation' }
];
const AuthenticationTypes = [{ name: 'Select Value', value: '' },
{ name: 'AAD AppId', value: 'AAD AppId' },
{ name: 'Dsts Blackforest', value: 'Dsts Blackforest' },
{ name: 'Dsts FairFax', value: 'Dsts FairFax' },
{ name: 'Dsts Mooncake', value: 'Dsts Mooncake' }
];
const DependsOnItems = [
    { name: 'No', value: 'No' }
];

const NegativeResponse = ["No", "Needed", "no", "needed"]

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
            dispatch({
                LoginuserId: LoginuserId,
                type: ActionType.LOGIN_USERNAME_OCE
            });
        }

    },
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    GetOnCallDashboardChecklistData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.FETCH_CHECKLIST_DATA
        });
        ChecklistAPI.GetOnCallDashboardCheckListData()
            .then((checklistData: IPropOnCallDashboardChecklist[]) => {

                dispatch({
                    checklistData,
                    type: ActionType.CHECKLIST_DATA_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.CHECKLIST_DATA_FAIL
                });
            });
        ChecklistAPI.GetOnCallDashboardServices()
            .then((servicelist: PropOnCallDashboardService[]) => {
                const slist = [];
                // slist.push({ name: 'Select Service', value: '' });
                if (isArrayWithLength(servicelist)) {
                    servicelist.map((obj) => {
                        slist.push({ name: obj.serviceDisplayName, value: obj.serviceID });
                    });
                }
                dispatch({
                    servicelist: slist,
                    type: ActionType.CHECKLIST_SERVICES_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.CHECKLIST_SERVICES_FAIL
                });
            });
        ChecklistAPI.GetOnCallDashboardTemplateData()
            .then((templatelist: IPropOnCallDashboardTemplate[]) => {
                const tlist = [];
                tlist.push({ name: 'Select Template', value: -1 });
                if (isArrayWithLength(templatelist)) {
                    templatelist.map((obj) => {
                        tlist.push({ name: obj.templateName, value: obj.templateId });
                    });
                }
                dispatch({
                    templatelist: tlist,
                    type: ActionType.CHECKLIST_TEMPLATE_DATA_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.CHECKLIST_TEMPLATE_DATA_FAIL
                });
            });
    },
    OpenchecklistPopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let checklist = {
            'checkListId': 0, 'selectedService': [], 'checkListName': '', 'notes': '', DataUrl: '', IsActive: true, templateId: -1, templateType: 'Checklist Only', 'isLocked': false
        };
        let question = {
            QuestionID: 0, question: '', ControlType: '', displayOrder: '', sourceType: '', dynamicValidation: '', controlValue: '', serverName: '', Database: '', Query: '',
            kustoauthenticationtype: '', dataurl: '', Questionindex: -1, DependsOn: 'No', DependsOnValue: '', IsdisplayDependsonValue: false, DependsOnValueslist: '',
            ddldependsonvaluelistarray: [], DependsOnItems: '', matchIndex: -1
        };
        dispatch({
            checklist,
            question,
            questioncontroltypeoptions,
            questioncontroltypedependsonoptions,
            sourcetypes,
            dynamicValidationTypes,
            AuthenticationTypes,
            DependsOnItems,
            templateTypes,
            type: ActionType.OPEN_CHECKLIST_POPUP
        });
    },
    ChecklistEdit: (checklist): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        //let checklist = {
        //    'checkListId': oldchecklist.checkListID, 'selectedService': [parseInt(oldchecklist.serviceID)], 'checkListName': oldchecklist.checkListName, 'notes': oldchecklist.notes,
        //    DataUrl: oldchecklist.dataUrl, IsActive: oldchecklist.isActive, templateId: oldchecklist.templateID, 'isLocked': oldchecklist.isLocked, templateType: oldchecklist.templateType
        //};
        //if (checklist.templateId == -1) {
        //    checklist.templateId = '';
        //}

        let qlist = [];
        let LoginuserId = getState().authUser.login;
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        ChecklistAPI.GetOnCallCheckListQuestions(checklist.checkListId)
            .then((checklistequestions: IPropOnCallDashboardQuestionnaire[]) => {
                if (isArrayWithLength(checklistequestions)) {
                    checklistequestions.map((obj) => {
                        qlist.push({
                            "QuestionID": obj.questionID,
                            "DisplayOrder": obj.displayOrder,
                            "DataUrl": obj.dataUrl,
                            "Question": obj.question,
                            "ControlType": obj.controlType,
                            "SourceType": obj.sourceType,
                            "DynamicValidation": obj.dynamicValidation,
                            "ServerName": obj.serverName,
                            "Database": obj.database,
                            "Query": obj.query,
                            "AuthenticationType": obj.authenticationType,
                            "IsActionRequired": obj.isActionRequired,
                            "ActionItemValue": obj.actionItemValue,
                            "ControlValues": obj.controlValues,
                            "IsActive": obj.isActive,
                            "LastUpdatedBy": LoginuserId,
                            "isChecked": true,
                            "Ischanged": true,
                            "DependsOn": obj.dependsOn,
                            "DependsOnValue": obj.dependentValue,
                            "DependsOnItems": (obj.dependsOn != "No") ? "No" + "," + obj.dependsOn : "No",
                            "TemplateID": obj.templateID,
                            'IndexValue': obj.indexValue,
                            'matchIndex': obj.matchIndex,
                            "IsdisplayDependsonValue": obj.isdisplayDependsonValue,
                            'DependsOnValueslist': obj.dependsOnValueslist,
                            'DependentValues': { 'ICMPicker': false, 'ActionItems': false },
                            'IsAnsweredBefore': obj.isAnsweredBefore
                        });
                    });
                    let matchobjects = [];
                    for (var i = 0; i < qlist.length; i++) {
                        if (qlist[i].DependsOn == 'No' && qlist[i].TemplateID === 0) {
                            for (var j = 0; j < qlist.length; j++) {
                                if (qlist[j].DependsOn == qlist[i].Question) {
                                    if (qlist[j].ControlType == 'ICMPicker') {
                                        qlist[i].DependentValues.ICMPicker = true;
                                    }
                                    else if (qlist[j].ControlType == 'ActionItems') {
                                        qlist[i].DependentValues.ActionItems = true;
                                    }
                                }
                            }

                            //On loading the checklist, If ControlType IS an 'optional' ControlType, allow it to be a Parent question for the following 2 questions
                            if (qlist[i].ControlType == 'CheckBox' || qlist[i].ControlType == 'Dropdown' || qlist[i].ControlType == 'Radiobutton') {
                                if ((i + 1) < qlist.length/* && qlist[i + 1].DependsOn == 'No'*/) {
                                    qlist[i + 1].DependsOnItems = 'No,' + qlist[i].Question;
                                    qlist[i + 1].DependsOnValueslist = qlist[i].ControlValues;
                                }
                                if ((i + 2) < qlist.length && qlist[i + 2].DependsOn == 'No') {
                                    qlist[i + 2].DependsOnItems = 'No,' + qlist[i].Question;
                                    qlist[i + 2].DependsOnValueslist = qlist[i].ControlValues;
                                }
                            }
                        }
                    }
                }
                let result = ValidateQuestionnaire(checklist, qlist, []);
                if (isArrayWithLength(qlist)) {
                    qlist.map((obj, index) => {
                        obj.IsInvalidQuestion = false;
                    });
                }
                if (isArrayWithLength(result.invQlist)) {
                    qlist.map((obj, index) => {
                        result.invQlist.map((value) => {
                            if (value === index) {
                                obj.IsInvalidQuestion = true;
                            }

                        })
                    });
                }
                let display_order = qlist.map(question => question.DisplayOrder);
                let unique_display_order = display_order.filter((d, i) => display_order.indexOf(d) === i);
                if (display_order.length !== unique_display_order.length) {
                    let template_qlist = qlist.filter(question => question.TemplateID !== 0);
                    let non_template_qlist = qlist.filter(question => question.TemplateID === 0);
                    qlist = template_qlist.concat(non_template_qlist);
                    qlist.map((question, index) => {
                        question.DisplayOrder = index + 1;
                    });
                }
                dispatch({
                    checklist,
                    QuestionarrieList: qlist,
                    ischecklistsavebuttondisabled: result.Disbalebutton,
                    InvalidQuestionList: result.invQlist,
                    questioncontroltypeoptions,
                    questioncontroltypedependsonoptions,
                    sourcetypes,
                    dynamicValidationTypes,
                    AuthenticationTypes,
                    templateTypes,
                    type: ActionType.CHECKLIST_EDIT
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
    },
    handlechecklistChange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let username = getState().authUser.login;
        let { value, name, checked } = e.target;
        let oldchecklist = getState().Checklist.checklist;
        let InvalidQuestionList = getState().Checklist.InvalidQuestionList;
        let qlist = getState().Checklist.QuestionarrieList;
        let checklist = {
            'checkListId': oldchecklist.checkListId, 'selectedService': oldchecklist.selectedService, 'checkListName': oldchecklist.checkListName, 'notes': oldchecklist.notes,
            DataUrl: oldchecklist.DataUrl, IsActive: oldchecklist.IsActive, templateId: oldchecklist.templateId, 'isLocked': oldchecklist.isLocked, 'templateType': oldchecklist.templateType
        };
        if (name === 'selectedService' || name === 'templateId') {
            checklist[name] = e.value;
            if (name === 'templateId' && e.value !== '') {
                let QuestionnarieList = [];
                dispatch({
                    type: ActionType.SET_LOADING_TRUE
                });
                let templateType = '';
                ChecklistAPI.GetChecklistTemplateTypeByTemplateId(e.value, checklist.checkListId).then((TemplateTypeData: any) => {
                    if (isArrayWithLength(TemplateTypeData)) {
                        templateType = TemplateTypeData[0].templateType;
                        checklist['templateType'] = templateType;
                    }
                    else {
                        checklist['templateType'] = templateType;
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                });
                ChecklistAPI.GetOnCallDashboardTemplateAndChecklistQuestionsByIds(e.value, checklist.checkListId)
                    .then((checklisttemplatequestions: IPropOnCallDashboardQuestionnaire[]) => {
                        if (isArrayWithLength(checklisttemplatequestions)) {
                            checklisttemplatequestions.map((obj) => {
                                QuestionnarieList.push({
                                    "QuestionID": obj.questionID,
                                    "DisplayOrder": obj.displayOrder,
                                    "DataUrl": obj.dataUrl,
                                    "Question": obj.question,
                                    "ControlType": obj.controlType,
                                    "SourceType": obj.sourceType,
                                    "DynamicValidation": obj.dynamicValidation,
                                    "ServerName": obj.serverName,
                                    "Database": obj.database,
                                    "Query": obj.query,
                                    "AuthenticationType": obj.authenticationType,
                                    "IsActionRequired": obj.isActionRequired,
                                    "ActionItemValue": obj.actionItemValue,
                                    "ControlValues": obj.controlValues,
                                    "IsActive": obj.isActive,
                                    "LastUpdatedBy": username,
                                    "isChecked": true,
                                    "Ischanged": true,
                                    "TemplateID": obj.templateID,
                                    "DependsOn": 'No',
                                    "DependsOnValue": '',
                                    "DependsOnItems": 'No',
                                    'DependentValues': { 'ICMPicker': false, 'ActionItems': false },
                                    'IndexValue': -1,
                                    'matchIndex': -1,
                                    'IsdisplayDependsonValue': false,
                                    'DependsOnValueslist': [],

                                });
                            });
                        }
                        let result = ValidateQuestionnaire(checklist, QuestionnarieList, InvalidQuestionList);
                        if (isArrayWithLength(QuestionnarieList)) {
                            QuestionnarieList.map((obj, index) => {
                                obj.IsInvalidQuestion = false;
                            });
                        }
                        if (isArrayWithLength(result.invQlist)) {
                            QuestionnarieList.map((obj, index) => {
                                result.invQlist.map((value) => {
                                    if (value === index) {
                                        obj.IsInvalidQuestion = true;
                                    }

                                })
                            });
                        }

                        dispatch({
                            ischecklistsavebuttondisabled: result.Disbalebutton,
                            InvalidQuestionList: result.invQlist,
                            QuestionarrieList: QuestionnarieList,
                            type: ActionType.CHECKLIST_TEMPLATE_QUESTIONS_SUCCESS
                        });

                    }).catch(error => {
                        dispatch({
                            type: ActionType.CHECKLIST_TEMPLATE_QUESTIONS_FAIL
                        });
                    });
            }


        }
        else if (name === 'IsActive') {
            checklist[name] = checked;
        }
        else {
            checklist[name] = value;
        }
        if (name === 'templateId' && e.value === '') {
            qlist = [];
        }
        let result = ValidateQuestionnaire(checklist, qlist, InvalidQuestionList);
        const QuestionarrieList = [];
        if (isArrayWithLength(qlist)) {
            qlist.map((obj, index) => {
                obj.IsInvalidQuestion = false;
            });
        }
        if (isArrayWithLength(result.invQlist)) {
            qlist.map((obj, index) => {
                result.invQlist.map((value) => {
                    if (value === index) {
                        obj.IsInvalidQuestion = true;
                    }
                })
                QuestionarrieList.push(obj);
            });
        }
        else {
            qlist.map((obj, index) => {
                obj.IsInvalidQuestion = false;
                QuestionarrieList.push(obj);
            });
        }
        dispatch({
            ischecklistsavebuttondisabled: result.Disbalebutton,
            InvalidQuestionList: result.invQlist,
            checklist,
            QuestionarrieList,
            type: ActionType.CHECKLIST_DATA_CHANGFE
        });
    },
    EditLockforChecklist: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let oldchecklist = getState().Checklist.checklist;
        let ischecklistsavebuttondisabled = getState().Checklist.ischecklistsavebuttondisabled;
        let InvalidQuestionList = getState().Checklist.InvalidQuestionList;
        let QuestionarrieList = getState().Checklist.QuestionarrieList;

        let checklist = {
            'checkListId': oldchecklist.checkListId, 'selectedService': oldchecklist.selectedService, 'checkListName': oldchecklist.checkListName, 'notes': oldchecklist.notes,
            DataUrl: oldchecklist.DataUrl, IsActive: oldchecklist.IsActive, templateId: oldchecklist.templateId, 'isLocked': oldchecklist.isLocked, templateType: oldchecklist.templateType
        };

        checklist.isLocked = !checklist.isLocked;
        dispatch({
            ischecklistsavebuttondisabled,
            InvalidQuestionList,
            checklist,
            QuestionarrieList,
            type: ActionType.CHECKLIST_DATA_CHANGFE
        });
    },
    hideCHECKLISTPopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showchecklistPopup = getState().Checklist.showchecklistPopup;
        dispatch({
            showchecklistPopup: !showchecklistPopup,
            type: ActionType.SHOWORHIDE_CHECKLIST_POPUP
        });
    },
    AddQuestionstoChecklist: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let QuestionnarieList = getState().Checklist.QuestionarrieList;
        let question = {
            QuestionID: 0, question: '', ControlType: '', displayOrder: '', sourceType: '', dynamicValidation: '', controlValue: '', serverName: '', Database: '', Query: '',
            kustoauthenticationtype: '', dataurl: '', Questionindex: -1, DependsOn: 'No', DependsOnValue: '', IsdisplayDependsonValue: false, DependsOnValueslist: '',
            ddldependsonvaluelistarray: [], DependsOnItems: '', matchIndex: -1
        }
        let DependsOnValue = '';
        let DependsOnItems = [{ name: 'No', value: 'No' }];
        var index = QuestionnarieList.length;
        //Find and Add probable parent questions as options
        if (index > 0) {
            if (QuestionnarieList[index - 1].DependsOn == 'No' && QuestionnarieList[index - 1].TemplateID == 0 && QuestionnarieList[index - 1].IsActive == true) {
                //Add previous question as dependent option only if it has ControlType as Checkbox, Radiobutton or dropdown
                if (QuestionnarieList[index - 1].ControlType == 'CheckBox' || QuestionnarieList[index - 1].ControlType == 'Dropdown' || QuestionnarieList[index - 1].ControlType == 'Radiobutton') {
                    if (QuestionnarieList[index - 1].DependentValues.ICMPicker == false || QuestionnarieList[index - 1].DependentValues.ActionItems == false) {
                        DependsOnItems.push({ name: QuestionnarieList[index - 1].Question, value: QuestionnarieList[index - 1].Question });

                    }
                }
            }
            else if (index > 1 && QuestionnarieList[index - 1].DependsOn != 'No' && QuestionnarieList[index - 1].DependsOn != '' && QuestionnarieList[index - 1].DependsOn != undefined) {
                if (QuestionnarieList[index - 2].DependsOn == 'No' && QuestionnarieList[index - 2].TemplateID == 0 && QuestionnarieList[index - 2].IsActive == true) {
                    //Add [index-2] question as dependent option only if it has ControlType as Checkbox, Radiobutton or dropdown
                    if (QuestionnarieList[index - 2].ControlType == 'CheckBox' || QuestionnarieList[index - 2].ControlType == 'Dropdown' || QuestionnarieList[index - 2].ControlType == 'Radiobutton') {
                        if (QuestionnarieList[index - 2].DependentValues.ICMPicker == false || QuestionnarieList[index - 2].DependentValues.ActionItems == false) {

                            DependsOnItems.push({ name: QuestionnarieList[index - 2].Question, value: QuestionnarieList[index - 2].Question });
                        }
                    }
                }
            }
        }
        if (!isArrayWithLength(DependsOnItems)) {
            DependsOnItems = [{ name: 'No', value: 'No' }];
        }
        let disableaddbuttonforquestion = Checknewquestiondata(question);
        dispatch({
            disableaddbuttonforquestion,
            question,
            DependsOnItems,
            type: ActionType.ENABLE_ADD_NEW_QUESTION
        });

    },
    handlequestionChange: (obj): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let oldquestion = getState().Checklist.question;
        let { name, value } = obj.target;
        let dependsonvaluelist = [];
        let QuestionarrieListdata = getState().Checklist.QuestionarrieList;
        let qlist = [];
        if (isArrayWithLength(QuestionarrieListdata)) {
            QuestionarrieListdata.map((obj, index) => {
                if (name === 'question') {
                    if (oldquestion.Questionindex !== -1 && obj.matchIndex == oldquestion.Questionindex && oldquestion.Questionindex != index && obj.IsActive == true) {
                        var items = obj.DependsOnItems.split(',');
                        // obj.Question = $scope.escapeChar($scope.QuestionnarieList[indexs].Question).replace(",", ";");                      
                        obj.DependsOn = value;
                        //obj.DependsOnItems = 'No,' + $scope.escapeChar($scope.QuestionnarieList[indexs].Question).replace(",", ";");
                        obj.DependsOnItems = 'No,' + value;
                    }
                }

                qlist.push(obj);
            });
        }
        let question = {
            QuestionID: oldquestion.QuestionID, question: oldquestion.question, ControlType: oldquestion.ControlType, displayOrder: oldquestion.displayOrder, sourceType: oldquestion.sourceType, dynamicValidation: oldquestion.dynamicValidation, controlValue: oldquestion.controlValue,
            serverName: oldquestion.serverName, Database: oldquestion.Database, Query: oldquestion.Query, kustoauthenticationtype: oldquestion.kustoauthenticationtype, dataurl: oldquestion.dataurl,
            Questionindex: oldquestion.Questionindex, DependsOn: oldquestion.DependsOn, DependsOnValue: oldquestion.DependsOnValue, DependsOnItems: oldquestion.DependsOnItems, matchIndex: oldquestion.matchIndex,
            IsdisplayDependsonValue: oldquestion.IsdisplayDependsonValue, DependsOnValueslist: oldquestion.DependsOnValueslist, ddldependsonvaluelistarray: oldquestion.ddldependsonvaluelistarray
        }
        if (name === 'ControlType' || name === 'sourceType' || name === 'dynamicValidation' || name === 'kustoauthenticationtype' || name === 'DependsOn' || name === 'DependsOnValue') {
            question[name] = obj.value;
        }
        else {
            question[name] = value;
        }
        if (name === 'sourceType') {
            question.serverName = '';
            question.Database = '';
            question.Query = '';
            question.kustoauthenticationtype = '';
            question.dataurl = '';

        }
        else if (name === 'ControlType') {
            question.controlValue = '';
            if (question.DependsOn === 'No') {
                //Iterate through every question to find its Child and 'possible' child in 'DependsOn' dropdown
                for (var i = 0; i < qlist.length; i++) {
                    if (qlist[i].id !== -1) {
                        //If question is Child, Reset dependant fields
                        if (qlist[i].DependsOn == question.question) {
                            //If its NOT 'optional' ControlType like checkbox, dropdown etc; Reset dependant fields
                            if (value == 'FileUpload' || value == 'Date' || value == 'TextBox' || value == 'TextArea') {
                                qlist[i].DependsOnItems = 'No';
                                qlist[i].DependsOn = 'No';
                                qlist[i].DependsOnValueslist = '';
                                qlist[i].ControlType = '';
                                qlist[i].controlValue = '';
                                if (question.question != undefined && question.question != null && question.question != '') {
                                    //when DependsOn is made 'No', reset the question
                                    if (question.DependsOn == 'No') {
                                        //If the next question is also a child question, reset the question, since a question can be a child only of the immediate parent question
                                        if ((question.Questionindex + 1) < qlist.length) {
                                            //if (qlist[index + 1].DependsOn != 'No') {
                                            qlist[question.Questionindex + 1].DependsOnItems = 'No';
                                            qlist[question.Questionindex + 1].DependsOnValueslist = '';
                                            qlist[question.Questionindex + 1].DependsOnValue = '';
                                            qlist[question.Questionindex + 1].DependsOn = 'No';

                                        }
                                        question.DependsOnValue = '';
                                        question.controlValue = '';
                                    }
                                    //when made as first child question, reset 'ControlType' dropdown and update 'Depends on Value' dropdown
                                    else if (question.DependsOn != 'No') {
                                        question.ControlType = '';
                                        question.IsdisplayDependsonValue = true;
                                        for (var i = 0; i < qlist.length; i++) {
                                            if (question.DependsOn == qlist[i].Question) {
                                                question.DependsOnValueslist = qlist[i].ControlValues;
                                            }
                                            //if this question has a child question, reset that question
                                            if (qlist[i].DependsOn == question.question) {
                                                qlist[i].DependsOnItems = 'No';
                                                qlist[i].DependsOn = 'No';
                                                qlist[i].DependsOnValueslist = '';
                                                qlist[i].ControlType = '';
                                            }
                                            //If this question is 'possible' child in 'DependsOn' dropdown, Reset dependant fields
                                            else if (qlist[i].DependsOnItems.split(',').includes(question.question)) {
                                                qlist[i].DependsOnItems = 'No';
                                                qlist[i].DependsOn = 'No';
                                                qlist[i].DependsOnValueslist = '';
                                            }
                                        }
                                        //Add parent question to next question's DependsOn dropdown to be able to make as second child; and add parent question's control values
                                        if ((question.Questionindex + 1) < qlist.length && qlist[question.Questionindex - 1].Question == question.DependsOn) {
                                            qlist[question.Questionindex + 1].DependsOnItems = 'No,' + qlist[question.Questionindex - 1].Question;
                                            qlist[question.Questionindex + 1].DependsOnValueslist = qlist[question.Questionindex - 1].ControlValues;
                                        }
                                    }
                                }
                                question.IsdisplayDependsonValue = false;
                            }
                            //If it IS 'optional' ControlType, update its 'Depends On Values' dropdown
                            else if (question.ControlType == 'CheckBox' || question.ControlType == 'Dropdown' || question.ControlType == 'Radiobutton') {
                                qlist[i].DependsOnValueslist = '';
                                question.DependsOnValueslist = qlist[i].ControlValues;
                                question.IsdisplayDependsonValue = true;
                            }
                        }
                        //If question is 'possible' child in 'DependsOn' dropdown, Reset dependant fields
                        else if (qlist[i].DependsOnItems.split(',').includes(question.question)) {
                            qlist[i].DependsOnItems = 'No';
                            qlist[i].DependsOn = 'No';
                            qlist[i].DependsOnValueslist = '';
                        }
                    }
                }
            }
        }
        else if (name === 'controlValue') {
            if (question.DependsOn === 'No') {
                for (var i = 0; i < qlist.length; i++) {
                    if (qlist[i].id !== -1) {
                        if (qlist[i].DependsOn == question.question) {
                            if (question.controlValue != '' || question.controlValue != undefined) {
                                qlist[i].DependsOnValueslist = question.controlValue;
                                qlist[i].DependsOnValue = '';
                            }
                            else {
                                qlist[i].DependsOnValueslist = '';
                                qlist[i].DependsOnValue = '';
                            }
                        }
                    }
                }
            }
        }
        else if (name === 'DependsOn') {
            question.ControlType = '';
            question.controlValue = '';
            question.DependsOnValue = '';

        }
        if (name === 'DependsOn') {
            if (value != 'No') {
                question.DependsOnValue = '';
                for (var i = 0; i < QuestionarrieListdata.length; i++) {
                    if (QuestionarrieListdata[i].id !== -1) {
                        if (QuestionarrieListdata[i].Question == question.DependsOn) {
                            if (QuestionarrieListdata[i].ControlType == 'FileUpload' || QuestionarrieListdata[i].ControlType == 'Date' || QuestionarrieListdata[i].ControlType == 'TextBox' || QuestionarrieListdata[i].ControlType == 'TextArea') {

                                question.IsdisplayDependsonValue = false;
                            }
                            else {
                                question.DependsOnValueslist = QuestionarrieListdata[i].ControlValues;
                                question.IsdisplayDependsonValue = true;
                                let CV_values = QuestionarrieListdata[i].ControlValues.split(',');
                                const lst = [];
                                lst.push({ name: 'Select Depends On Value', value: '' });
                                for (let cv = 0; cv < CV_values.length; cv++) {
                                    lst.push({ name: CV_values[cv], value: CV_values[cv] });
                                }
                                question.ddldependsonvaluelistarray = lst;
                            }
                        }
                    }
                }

            }

        }

        let disableaddbuttonforquestion = Checknewquestiondata(question);
        dispatch({
            disableaddbuttonforquestion,
            QuestionarrieList: qlist,
            question,
            type: ActionType.QUESTION_CONTROLS_CHANGFE
        });

    },
    showquestionlist: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SHOW_QUESTIONLIST
        });

    },
    ChecklistquestionEdit: (lst, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let qlist = getState().Checklist.QuestionarrieList;
        let question = {};
        let DependsOnItems = [];

        if (isArrayWithLength(qlist)) {
            qlist.map((oldquestion, key) => {
                if (index === key) {
                    var items = oldquestion.DependsOnItems.split(',');
                    var DependsOnValueslist = oldquestion.DependsOnValueslist.split(',');
                    let ddldependsonvaluelistarray = [];
                    ddldependsonvaluelistarray.push({ name: 'Select Depends On Value', value: '' });
                    if (isArrayWithLength(DependsOnValueslist)) {
                        DependsOnValueslist.map((obj) => {
                            ddldependsonvaluelistarray.push({ name: obj, value: obj });
                        })
                    }
                    if (isArrayWithLength(items)) {
                        items.map((obj) => {
                            DependsOnItems.push({ name: obj, value: obj });
                        })
                    }
                    else {
                        DependsOnItems.push({ name: 'No', value: 'No' });
                    }
                    question = {
                        QuestionID: oldquestion.QuestionID, question: oldquestion.Question, ControlType: oldquestion.ControlType, displayOrder: oldquestion.DisplayOrder,
                        sourceType: oldquestion.SourceType, dynamicValidation: oldquestion.DynamicValidation, controlValue: oldquestion.ControlValues,
                        serverName: oldquestion.ServerName, Database: oldquestion.Database, Query: oldquestion.Query, kustoauthenticationtype: oldquestion.AuthenticationType, dataurl: oldquestion.DataUrl,
                        Questionindex: index, DependsOn: oldquestion.DependsOn, DependsOnValue: oldquestion.DependsOnValue,
                        IsdisplayDependsonValue: oldquestion.IsdisplayDependsonValue, DependsOnValueslist: oldquestion.DependsOnValueslist,
                        ddldependsonvaluelistarray: ddldependsonvaluelistarray, DependsOnItems: oldquestion.DependsOnItems, matchIndex: oldquestion.matchIndex
                    }


                }
            });
        }
        let disableaddbuttonforquestion = Checknewquestiondata(question);
        dispatch({
            disableaddbuttonforquestion,
            question,
            DependsOnItems,
            type: ActionType.ENABLE_ADD_NEW_QUESTION
        });
    },
    questionlistvalueschange: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let checklist = getState().Checklist.checklist;
        let InvalidQuestionList = getState().Checklist.InvalidQuestionList;
        const { name, value, checked } = e.target;
        let qlist = getState().Checklist.QuestionarrieList;
        const QuestionarrieList = [];
        if (isArrayWithLength(qlist)) {
            qlist.map((obj, key) => {
                if (key === index) {
                    if (name === 'Question') {
                        obj[name] = value;

                    }
                    else {
                        obj[name] = checked;
                    }
                }

                QuestionarrieList.push(obj);
            })
        }
        for (var i = 0; i < QuestionarrieList.length; i++) {
            if (QuestionarrieList[i].matchIndex == index && i != index && QuestionarrieList[i].IsActive == true) {
                QuestionarrieList[i].DependsOnItems = 'No,' + value;
                QuestionarrieList[i].DependsOn = value;
            }
        }
        let result = ValidateQuestionnaire(checklist, QuestionarrieList, InvalidQuestionList);
        if (isArrayWithLength(QuestionarrieList)) {
            QuestionarrieList.map((obj, index) => {
                obj.IsInvalidQuestion = false;
            });
        }
        if (isArrayWithLength(result.invQlist)) {
            QuestionarrieList.map((obj, index) => {
                result.invQlist.map((value) => {
                    if (value === index) {
                        obj.IsInvalidQuestion = true;
                    }

                })
            });
        }

        dispatch({
            ischecklistsavebuttondisabled: result.Disbalebutton,
            InvalidQuestionList: result.invQlist,
            QuestionarrieList,
            type: ActionType.ADD_QUESTIONS_TOLIST
        });
    },
    AddNewquestiontolist: (lst, Questionindex, qlist): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let checklist = getState().Checklist.checklist;
        let InvalidQuestionList = getState().Checklist.InvalidQuestionList;

        const QuestionarrieList = [];
        if (isArrayWithLength(qlist)) {
            qlist.map((obj, key) => {
                if (key === Questionindex) {
                    QuestionarrieList.push(lst);
                }
                else {
                    QuestionarrieList.push(obj);
                }
            });
        }
        if (Questionindex === -1) {
            QuestionarrieList.push(lst);
        }
        let result = ValidateQuestionnaire(checklist, QuestionarrieList, InvalidQuestionList);
        if (isArrayWithLength(QuestionarrieList)) {
            QuestionarrieList.map((obj, index) => {
                obj.IsInvalidQuestion = false;
            });
        }
        if (isArrayWithLength(result.invQlist)) {
            QuestionarrieList.map((obj, index) => {
                result.invQlist.map((value) => {
                    if (value === index) {
                        obj.IsInvalidQuestion = true;
                    }
                })
            });
        }

        dispatch({
            ischecklistsavebuttondisabled: result.Disbalebutton,
            InvalidQuestionList: result.invQlist,
            //istemplatesavebuttondisabled: istemplatesavebuttondisabled,
            QuestionarrieList,
            type: ActionType.ADD_QUESTIONS_TOLIST
        });

    },
    //--------------------- OCE View actions --------------------------------------//
    onfromDateChange: (fromDate: Date): ReduxAction => ({
        fromDate: fromDate,
        type: ActionType.OCELIST_FROMDATE_CHANGE
    }),
    ontoDateChange: (toDate: Date): ReduxAction => ({
        toDate: toDate,
        type: ActionType.OCELIST_TODATE_CHANGE
    }),
    incidentFilterFromDateChange: (incidentFilterFromDate): ReduxAction => ({
        incidentFilterFromDate: incidentFilterFromDate,
        type: ActionType.INCIDENTFILTER_FROMDATE_CHANGE
    }),
    incidentFilterToDateChange: (incidentFilterToDate): ReduxAction => ({
        incidentFilterToDate: incidentFilterToDate,
        type: ActionType.INCIDENTFILTER_TODATE_CHANGE
    }),
    CheckListchange: (value): ReduxAction => ({
        checklistID: value,
        type: ActionType.CHECKLIST_DROPDOWN_CHANGE
    }),
    IncidentIdchange: (value): ReduxAction => ({
        mainIncidentIdFilter: value,
        type: ActionType.INCIDENT_DROPDOWN_CHANGE
    }),
    TemplateTypechange: (value): ReduxAction => ({
        templateType: value,
        type: ActionType.TEMPLATETYPE_DROPDOWN_CHANGE
    }),
    submittedbyChange: (value): ReduxAction => ({
        createdby: value,
        type: ActionType.SUBMITTEDBY_DROPDOWN_CHANGE
    }),
    ChangedSearchText: (e): ReduxAction => ({
        searchText: e.target.value,
        type: ActionType.OCELIST_SEARCHTEXT_CHANGE
    }),
    ChangedmeetingTitle: (e): ReduxAction => ({
        meetingTitle: e.target.value,
        type: ActionType.OCELIST_MEETINGTITLE_CHANGE
    }),
    updateIcmMainIncident: (e): ReduxAction => ({
        icmMainIncident: e.target.value,
        type: ActionType.OCELIST_ICMINCIDENT_CHANGE
    }),
    onCallDateChange: (onCallDate: Date): ReduxAction => ({
        onCallDate: onCallDate,
        type: ActionType.OCELIST_ONCALLDATE_CHANGE
    }),
    GetChecklistDashboardDate: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        const fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);
        const toDate = new Date();
        dispatch({
            fromDate,
            toDate,
            type: ActionType.SET_CHECKLISTDASHBOARDDATE
        });
    },
    GetChecklistDashboardData: (Callcount): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_CHECKLISTDATA_REQUEST
        });
        let count = 0;
        if (Callcount != 4) {
            count = 4;
        }
        if (Callcount === 1 || Callcount === 4) {
            //==================== to get data for Main Incidents =================//
            ChecklistAPI.GetMeetingMainIncidentIds()
                .then((MeetingIncidentIdsRaw: []) => {
                    const mainIncidentIdFilterList = [];
                    mainIncidentIdFilterList.push({ 'name': 'Select ICM Id', 'value': 0 });
                    if (isArrayWithLength(MeetingIncidentIdsRaw)) {
                        MeetingIncidentIdsRaw.map((item: any) => {
                            mainIncidentIdFilterList.push({ 'name': item.icmMainIncident, 'value': item.icmMainIncident });
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        mainIncidentIdFilterList,
                        mainIncidentIdFilter: 0,
                        type: ActionType.GET_MEETINGMAININCIDENTIDS_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_MEETINGMAININCIDENTIDS_FAIL
                    });
                });
        }
        if (Callcount === 2 || Callcount === 4) {
            //==================== to get data for Services =================//
            ChecklistAPI.GetChecklistServicesforOCEViewReadonly()
                .then((serviceRaw: IPropOnCallChecklistService[]) => {
                    const allServicesdata = [];
                    allServicesdata.push({ 'name': 'Select Service', 'value': 0 });
                    if (isArrayWithLength(serviceRaw)) {
                        serviceRaw.map((item: IPropOnCallChecklistService) => {
                            allServicesdata.push({ 'name': item.serviceDisplayName, 'value': item.serviceID });
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        allServicesdata,
                        selectedService: { 'name': allServicesdata[0].name, 'value': allServicesdata[0].value },
                        serviceId: 0,
                        type: ActionType.GET_CHECKLISTSERVICES_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_CHECKLISTSERVICES_FAIL
                    });
                });
        }
        if (Callcount === 3 || Callcount === 4) {
            //==================== to get data for Submitted List =================//
            ChecklistAPI.GetSubmittedByList()
                .then((dataRaw: []) => {
                    const submittedbylist = [];
                    submittedbylist.push({ name: 'Select', value: '' });
                    if (isArrayWithLength(dataRaw)) {
                        dataRaw.map((obj: any) => {
                            submittedbylist.push({ name: obj.name, value: obj.id });
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        submittedbylist,
                        type: ActionType.GET_SUBMITTEDLIST_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SUBMITTEDLIST_FAIL
                    });
                });
        }
        if (Callcount === 4 || Callcount === 5) {
            // setting the serviceId value to 0 on pageload
            var serviceId = 0;
            if (serviceId != 0) {
                //==================== to get data for Checklist List =================//
                ChecklistAPI.GetChecklistSforOCEView(serviceId)
                    .then((checklistdataRaw: []) => {
                        const checklists = [];
                        checklists.push({ name: 'Select CheckList', value: 0 });
                        if (isArrayWithLength(checklistdataRaw)) {
                            checklistdataRaw.map((obj: any) => {
                                checklists.push({ name: obj.checkListName, value: obj.checkListID });
                            })
                        }
                        count = count + 1;
                        dispatch({
                            count: count,
                            checklists,
                            serviceId: serviceId,
                            checklistID: 0,
                            selectedchecklist: { 'name': checklists[0].name, 'value': checklists[0].value },
                            type: ActionType.GET_CHECKLIST_SUCCESS
                        });
                    }).catch(error => {
                        count = count + 1;
                        dispatch({
                            count: count,
                            type: ActionType.GET_CHECKLIST_FAIL
                        });
                    });
            }
            else {
                const checklists = [];
                checklists.push({ name: 'Select CheckList', value: 0 });

                count = count + 1;
                dispatch({
                    count: count,
                    checklists,
                    serviceId: 0,
                    checklistID: 0,
                    selectedchecklist: { 'name': checklists[0].name, 'value': checklists[0].value },
                    type: ActionType.GET_CHECKLIST_SUCCESS
                });
            }

        }
    },
    GetOCEList: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var sortColumn = '';
        var sortOrder = false;
        var fromDate = getState().Checklist.fromDate;
        var toDate = getState().Checklist.toDate;
        var serviceId = getState().Checklist.serviceId;
        var checklistID = getState().Checklist.checklistID;
        var createdby = getState().Checklist.createdby;
        var mainIncidentIdFilter = getState().Checklist.mainIncidentIdFilter;
        var templateType = getState().Checklist.templateType;
        if (fromDate === undefined || fromDate === '' || fromDate === null) {
            fromDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)).format("MM/DD/YYYY");
        }
        else {
            fromDate = moment(fromDate).format("MM/DD/YYYY")
        }
        if (toDate === undefined || toDate === '' || toDate === null) {
            toDate = moment(new Date()).format("MM/DD/YYYY");
        }
        else {
            toDate = moment(toDate).format("MM/DD/YYYY")
        }
        if (moment(fromDate).isValid() && moment(toDate).isValid()) {
            if (moment(toDate) < moment(fromDate)) {
                toast.error(renderToastifyMsg('To Date must be later than From Date',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            }
        }
        if (serviceId === undefined || serviceId === null) {
            serviceId = 0;
        }
        if (checklistID === undefined || checklistID === '') {
            checklistID = 0;
        }
        if (createdby === undefined || createdby === '') {
            createdby = '';
        }
        if (mainIncidentIdFilter === undefined || mainIncidentIdFilter === null) {
            mainIncidentIdFilter = '';
        }
        if (templateType === undefined || templateType === null) {
            templateType = '';
        }
        ChecklistAPI.GetOCEList(serviceId, checklistID, fromDate, toDate, createdby, mainIncidentIdFilter, templateType)
            .then((OCEListdataRaw: OCEView[]) => {
                const oceList = [];
                if (isArrayWithLength(OCEListdataRaw)) {
                    OCEListdataRaw.map((obj: OCEView) => {
                        oceList.push(obj);
                    })

                    oceList.map((row: OCEView) => {
                        row.meetingDateForSort = moment(row.meetingDate).format("YYYY-MM-DD");
                        row.onCallDateForSort = moment(row.onCallDate).format("YYYY-MM-DD");
                    })
                }
                dispatch({
                    oceList,
                    sortColumn,
                    sortOrder,
                    type: ActionType.GET_OCELIST_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_OCELIST_FAIL
                });
            });
    },
    GetcheckList: (serviceIdValue): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        //==================== to get data for Checklist List =================//
        ChecklistAPI.GetChecklistSforOCEView(serviceIdValue)
            .then((checklistdataRaw: []) => {
                const checklists = [];
                checklists.push({ name: 'Select CheckList', value: 0 });
                if (isArrayWithLength(checklistdataRaw)) {
                    checklistdataRaw.map((obj: any) => {
                        checklists.push({ name: obj.checkListName, value: obj.checkListID });
                    })
                }
                dispatch({
                    checklists,
                    checklistID: 0,
                    serviceId: serviceIdValue,
                    selectedchecklist: { 'name': checklists[0].name, 'value': checklists[0].value },
                    type: ActionType.GET_CHECKLIST_SUCCESS
                });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_CHECKLIST_FAIL
                });
            });
    },
    ClearFilter: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.CHECKLISTDASHBOARD_CLEARFILTER
        });
    },
    sortOCEList: (event, column): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var sortColumn = getState().Checklist.sortColumn;
        var sortOrder = getState().Checklist.sortOrder;
        var oceList = getState().Checklist.oceList;
        var OCEListFiltered = [];
        if (sortColumn === column) {
            sortOrder = !sortOrder;
        } else {
            sortColumn = column;
            sortOrder = false;
        }
        // Logic to sort the array based on selected column and order
        OCEListFiltered = (sortOrder === true)
            ? oceList.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? -1 : 1)
            : oceList.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? 1 : -1)

        dispatch({
            sortColumn,
            sortOrder,
            oceList: OCEListFiltered,
            type: ActionType.SORT_OCELIST
        });
    },
    GetChecklistServicesforOCEView: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let url = getState().Checklist.url;
        let checklistID = url.CId;
        let onCallDate = getState().Checklist.onCallDate;
        let templateType = getState().Checklist.templateType;
        if (getState().Checklist.oceList == "") {
            ChecklistAPI.GetChecklistTemplateType(checklistID)
                .then((TemplateTypeData: any) => {
                    if (isArrayWithLength(TemplateTypeData)) {
                        templateType = TemplateTypeData[0].templateType;
                        dispatch({
                            templateType: templateType,
                            type: ActionType.GET_CHECKLISTS_TEMPLATETYPE_OCEVIEW
                        });
                    }
                    else {
                        dispatch({
                            templateType: templateType,
                            type: ActionType.GET_CHECKLISTS_TEMPLATETYPE_OCEVIEW
                        });
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                });
            ChecklistAPI.GetChecklistServicesforOCEViewReadonly()
                .then((serviceRaw: IPropOnCallChecklistService[]) => {
                    const allServicesdata = [];
                    allServicesdata.push({ 'name': 'Select Service', 'value': 0 });
                    if (isArrayWithLength(serviceRaw)) {
                        serviceRaw.map((item: IPropOnCallChecklistService) => {
                            allServicesdata.push({ 'name': item.serviceDisplayName, 'value': item.serviceID });
                        })
                    }
                    dispatch({
                        allServicesdata,
                        selectedServiceName: allServicesdata.find(x => x.value == url.SId).name,
                        selectedService: { 'name': allServicesdata[0].name, 'value': allServicesdata[0].value },
                        type: ActionType.GET_CHECKLISTSERVICES_SUCCESS_READONLY
                    });
                }).catch(error => {
                    dispatch({
                        type: ActionType.GET_CHECKLISTSERVICES_FAIL
                    });
                });
            ChecklistAPI.GetChecklistServicesforOCEView()
                .then((serviceRaw: IPropOnCallChecklistService[]) => {
                    const allServices = [];
                    let serviceId;
                    allServices.push({ 'name': 'Select Service', 'value': 0 });
                    serviceRaw.map((item: IPropOnCallChecklistService) => {
                        allServices.push({ 'name': item.serviceDisplayName, 'value': item.serviceID });
                    })
                    if (url.SId !== 0 && url.SId !== undefined && url.SId !== null) {
                        serviceId = parseInt(url.SId);
                        dispatch({
                            allServices: allServices,
                            serviceId: serviceId,
                            selectedServiceName: allServices.find(x => x.value == url.SId).name,
                            selectedService: { 'name': allServices[0].name, 'value': allServices[0].value },
                            type: ActionType.GET_CHECKLISTSERVICES_SUCCESS_OCEVIEW
                        });
                        CallGetChecklistSforOCEView(dispatch);
                    }
                    else {
                        dispatch({
                            type: ActionType.SET_LOADING_FALSE
                        });
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.GET_CHECKLISTSERVICES_OCEVIEW_FAIL
                    });
                });
        }
        else {
            if (templateType == '' && !getState().Checklist.oceList.some(x => x.checkListId == checklistID)) {
                ChecklistAPI.GetChecklistTemplateType(checklistID)
                    .then((TemplateTypeData: any) => {
                        if (isArrayWithLength(TemplateTypeData)) {
                            templateType = TemplateTypeData[0].templateType;
                            dispatch({
                                templateType: templateType,
                                type: ActionType.GET_CHECKLISTS_TEMPLATETYPE_OCEVIEW
                            });
                        }
                        else {
                            dispatch({
                                templateType: templateType,
                                type: ActionType.GET_CHECKLISTS_TEMPLATETYPE_OCEVIEW
                            });
                        }
                    }).catch(error => {
                        dispatch({
                            type: ActionType.SET_LOADING_FALSE
                        });
                    });
            }
            else if (getState().Checklist.oceList.some(x => x.checkListId == checklistID)) {
                templateType = getState().Checklist.oceList.find(x => x.checkListId == checklistID).templateType;
            }
            const allServices = [];
            const checklists = [];

            getState().Checklist.allServicesdata.map((item: IPropOnCallChecklistService) => {
                allServices.push({ 'name': item.serviceDisplayName, 'value': item.serviceID });
            })
            checklists.push({ name: 'Select CheckList', value: 0 });
            checklists.push({ name: (url.isNew) ? getState().Checklist.checklists.find(x => x.value == checklistID).name : getState().Checklist.oceList.find(x => x.checkListId == checklistID).checkListName, value: checklistID });
            let selectedChecklistName = (url.isNew) ? getState().Checklist.checklists.find(x => x.value == checklistID).name : getState().Checklist.oceList.find(x => x.checkListId == checklistID).checkListName;
            let selectedServiceName = getState().Checklist.allServicesdata.find(x => x.value == url.SId).name;
            dispatch({
                templateType: templateType,
                allServicesdata: getState().Checklist.allServicesdata,
                selectedService: { 'name': getState().Checklist.allServicesdata.find(x => x.value == url.SId).name, 'value': parseInt(url.SId) },
                allServices: allServices,
                serviceId: parseInt(url.SId),
                checklists: checklists,
                checklistID: checklistID,
                selectedchecklist: { 'name': (url.isNew) ? getState().Checklist.checklists.find(x => x.value == checklistID).name : getState().Checklist.oceList.find(x => x.checkListId == checklistID).checkListName, 'value': checklistID },
                question: {},
                isVisibleContent: false,
                selectedChecklistName: selectedChecklistName,
                selectedServiceName: selectedServiceName,
                type: ActionType.GET_UIFOROCE_DATA
            });
            if (url.isNew) {
                ChecklistAPI.CheckNextOnCallDate().then((response) => {
                    if (response != "") {
                        dispatch({
                            onCallDate: new Date(response),
                            type: ActionType.OCELIST_ONCALLDATE_DISABLE
                        });
                    }
                    else {
                        dispatch({
                            onCallDate: onCallDate,
                            type: ActionType.OCELIST_ONCALLDATE_DISABLE
                        });
                    }
                }).catch(error => {
                    toast.error(renderToastifyMsg('Error in fetching oncall data. Please try again.',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                });
            }
            CallLoad_Data_OCEView(dispatch, 0)
        }
    },
    GetChecklistSforOCEView: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var isVisibleContent = false;
        var question = [];
        var selectedchecklist = null;
        var checklistID = undefined;
        let url = getState().Checklist.url;
        let serviceId = getState().Checklist.serviceId;
        let onCallDate = getState().Checklist.onCallDate;

        //==================== to get data for Checklist List =================//
        ChecklistAPI.GetChecklistSforOCEView(serviceId)
            .then((checklistdataRaw: []) => {
                if (url.isNew) {
                    ChecklistAPI.CheckNextOnCallDate().then((response) => {
                        if (response != "") {
                            dispatch({
                                onCallDate: new Date(response),
                                type: ActionType.OCELIST_ONCALLDATE_DISABLE
                            });
                        }
                        else {
                            dispatch({
                                onCallDate: onCallDate,
                                type: ActionType.OCELIST_ONCALLDATE_DISABLE
                            });
                        }
                    }).catch(error => {
                        toast.error(renderToastifyMsg('Error in fetching oncall data. Please try again.',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                    });
                }
                const checklists = [];
                checklists.push({ name: 'Select CheckList', value: 0 });
                checklistdataRaw.map((obj: any) => {
                    checklists.push({ name: obj.checkListName, value: obj.checkListID });
                })
                selectedchecklist = { 'name': checklists[0].name, 'value': checklists[0].value };
                if (url.CId !== 0 && url.CId !== undefined && url.CId !== null) {
                    checklistID = parseInt(url.CId);
                    dispatch({
                        checklists: checklists,
                        checklistID: checklistID,
                        selectedchecklist: selectedchecklist,
                        question: question,
                        isVisibleContent: isVisibleContent,
                        serviceId: serviceId,
                        selectedChecklistName: checklists.find(x => x.value == url.CId).name,
                        type: ActionType.GET_CHECKLIST_SUCCESS_OCEVIEW
                    });
                    CallLoad_Data_OCEView(dispatch, 0);
                }
                else {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_CHECKLIST_FAIL
                });
            });
    },
    Load_Data_OCEView: (val): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        /* Code Block for Loading the Questions */
        let showExpand = getState().Checklist.showExpand;
        showExpand = false;
        //Reset iFrame Kusto Query and Data URL
        let iFrameKustoObj = {};
        let iFrameDataUrl = '';
        let isVisibleContent = getState().Checklist.isVisibleContent;
        let checklistID = getState().Checklist.checklistID;
        let meetingId = getState().Checklist.meetingId;
        let fromDate = getState().Checklist.fromDate;
        let onCallDate = getState().Checklist.fromDate;
        let serviceId = getState().Checklist.serviceId;
        let pageName = getState().Checklist.urlParam;
        if ((Checknulls(serviceId) == true)) {
            toast.error(renderToastifyMsg('Please select service',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            return;
        }
        else if ((Checknulls(checklistID) == true)) {
            toast.error(renderToastifyMsg('Please select Checklist',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            return;
        }
        else {
            isVisibleContent = true;
        }
        let actionDataTobeSaved = false;
        let isEditable = true;
        let isreadonly = false;
        let headerText = 'OCE View';
        let incidents = [];
        let allPIRData = [];
        let isIncidents = false;
        let isPIR = false;
        let questionsList = null;
        let filterparameters: any = {};
        filterparameters.ServiceId = serviceId;
        filterparameters.ChecklistId = checklistID.toString();
        filterparameters.MeetingId = meetingId;
        filterparameters.QuestionId = 1;
        filterparameters.ChoiceId = 0;
        filterparameters.CloudId = 0;
        filterparameters.IsLastQues = false;
        filterparameters.MeetingDate = moment(fromDate).format("MM/DD/YYYY");
        filterparameters.IsFristRequest = true;
        filterparameters.pageName = pageName;

        dispatch({
            showExpand: showExpand,
            checklistID: checklistID,
            iFrameKustoObj: iFrameKustoObj,
            iFrameDataUrl: iFrameDataUrl,
            isVisibleContent: isVisibleContent,
            actionDataTobeSaved: actionDataTobeSaved,
            isEditable: isEditable,
            isreadonly: isreadonly,
            headerText: headerText,
            incidents: incidents,
            allPIRData: allPIRData,
            isIncidents: isIncidents,
            isPIR: isPIR,
            questionsList: questionsList,
            filterparameters: filterparameters,
            type: ActionType.GET_CHECKLIST_OCEVIEW_LOADDATA
        });
        /* End of Code Block for Loading the Questions */
        CallLoadingQuestionsOCE(dispatch, val);
    },
    LoadingQuestionsOCE: (val): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let filterparameters = getState().Checklist.filterparameters;
        let serviceId = getState().Checklist.serviceId;
        //Code block for Loading Questions
        if (serviceId !== 0) {
            let question = [];
            let q_Index = 0;
            let isVisibleContent = false;
            let allowDateChange = getState().Checklist.allowDateChange;
            let URLOCE = getState().Checklist.URLOCE;
            let url = getState().Checklist.url;
            let showExpand = getState().Checklist.showExpand;
            let questionURL = getState().Checklist.questionURL;
            let onCallDate = getState().Checklist.onCallDate;
            let LoginuserId = getState().Checklist.LoginuserId;
            let checklistDashboardCreatedBy = "";
            let errorMsg = "";
            let pageName = getState().Checklist.urlParam;

            ChecklistAPI.GetChecklistQuestionsByChoiceForOCE(filterparameters)
                .then((questionsychoiceRaw: ChecklistQuestionsByChoiceForOce[]) => {
                    if (isArrayWithLength(questionsychoiceRaw)) {
                        isVisibleContent = true;
                        questionsychoiceRaw.map((obj: ChecklistQuestionsByChoiceForOce) => {
                            question.push(obj);
                        })
                        URLOCE = question[0].dataUrl;
                        checklistDashboardCreatedBy = question[0].createdBy;
                        //if (checklistDashboardCreatedBy !== LoginuserId) {                           
                        //    toast.error(renderToastifyMsg('You do not have permission to edit this checklist...',
                        //        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                        //    dispatch({
                        //        type: ActionType.ACCESS_DENIED
                        //    });
                        //    return;
                        //}
                        question.map((item: any, index) => {
                            if (item.isReadonly === true) {
                                item.color = "#A9A9A9";
                            }
                            else {
                                item.color = "#FFFFFF";
                            }
                            item.isChanged = (item.isSelected === 1) ? true : false;

                            if (item.isChanged) {
                                allowDateChange = false;
                            }
                            if (item.isChanged === true) {
                                item.headerClass = "OCEpanel-heading collapsed";
                                item.panelclass = "collapse";
                                showExpand = true;
                            }
                            else {
                                if (item.isReadonly === false) {
                                    item.headerClass = "OCEpanel-heading";
                                    item.panelclass = "collapse show";
                                }
                                else {
                                    item.headerClass = "OCEpanel-heading collapsed";
                                    item.panelclass = "collapse";
                                }

                            }
                            if (item.controlType === "CheckBox") {
                                item.chkvalue = {};
                                if (item.oceControlValue !== '') {
                                    item.showActionCheckbox = true;
                                    var checkboxvalues = item.oceControlValue.split(',');
                                    question = chkchangewhileloading(question, index, checkboxvalues);
                                    checkboxvalues.map((obj: any) => {
                                        item.chkvalue[obj] = true
                                    })
                                }
                            }
                            if (item.sourceType === 'URL') {
                                if (index == 0) {
                                    //  questionURL = $sce.trustAsResourceUrl(item.questionUrl.toString());
                                    questionURL = item.questionUrl.toString();
                                }

                            }
                            if (val === 1) {
                                item.isActionRequired = item.isActionRequired.toString();
                            }
                            if (item.controlType === "Radiobutton") {
                                if (item.controlValues == 'Current,Needed') {
                                    if (item.oceControlValue == 'Current') {
                                        item.isChanged = true;
                                    }
                                    else if (item.oceControlValue == 'Needed') {
                                        item.isChanged = false;
                                    }
                                }
                            }
                            if (pageName != 'UIforReadOnlyOCEView') {
                                //Added below condition to update the dynamic question answers based on user override
                                if (item.oceControlValue === '') {
                                    if (item.dynamicValidation == 'OnCallCertification') {
                                        if (!isNullOrUndefined(item.ocCertificationDate) && (item.validationStatus == true)) {
                                            let certificateCompletionDate = new Date(item.ocCertificationDate);
                                            let cDate = new Date(onCallDate);
                                            (cDate > certificateCompletionDate) ? (item.dynamicValidationStatus = true) : (item.dynamicValidationStatus = false);
                                            if (item.controlType === "CheckBox") {
                                                var checkboxvalues = item.controlValues.split(',');
                                                if (item.dynamicValidationStatus == true) {
                                                    checkboxvalues.map((obj: any) => {
                                                        if (obj == 'Yes' || obj == 'Current') {
                                                            item.oceControlValue = obj;
                                                            item.chkvalue[obj] = true;
                                                            item.isChanged = true;
                                                        }
                                                    })
                                                }
                                                else {
                                                    checkboxvalues.map((obj: any) => {
                                                        if (obj == 'No' || obj == 'Needed') {
                                                            item.oceControlValue = obj;
                                                            item.chkvalue[obj] = true;
                                                            item.isChanged = false;
                                                        }
                                                    })
                                                }                                             
                                            }
                                            else if (item.controlType === "Radiobutton") {
                                                if (item.dynamicValidationStatus == true) {
                                                    if (item.controlValues == 'Yes,No') {
                                                        item.oceControlValue = 'Yes';
                                                        item.isChanged = true;
                                                    }
                                                    else if (item.controlValues == 'Current,Needed') {
                                                        item.oceControlValue = 'Current'
                                                        item.isChanged = true;
                                                    }
                                                }
                                                else {
                                                    if (item.controlValues == 'Yes,No') {
                                                        item.oceControlValue = 'No';
                                                        item.isChanged = false;
                                                    }
                                                    else if (item.controlValues == 'Current,Needed') {
                                                        item.oceControlValue = 'Needed'
                                                        item.isChanged = false;
                                                    }
                                                }
                                            }
                                        }
                                        else if (isNullOrUndefined(item.ocCertificationDate)) {
                                            if (item.controlType === "CheckBox") {
                                                var checkboxvalues = item.controlValues.split(',');
                                                checkboxvalues.map((obj: any) => {
                                                    if (obj == "Needed" || obj == "No") {
                                                        item.oceControlValue = obj;
                                                        item.chkvalue[obj] = true;
                                                        item.isChanged = false;
                                                    }
                                                })
                                            }
                                            else if (item.controlType === "Radiobutton") {
                                                if (item.controlValues == 'Yes,No') {
                                                    item.oceControlValue = 'No';
                                                    item.isChanged = false;
                                                }
                                                else if (item.controlValues == 'Current,Needed') {
                                                    item.oceControlValue = 'Needed'
                                                    item.isChanged = false;
                                                }
                                            }
                                        }
                                    }
                                    if (item.dynamicValidation == 'WikiUpdatedDate') {
                                        if (item.controlType === "CheckBox") {
                                            var checkboxvalues = item.controlValues.split(',');
                                            checkboxvalues.map((obj: any) => {
                                                if (item.validationStatus == true) {
                                                    if (obj == 'Yes' || obj == 'Current') {
                                                        item.chkvalue[obj] = true;
                                                        item.isChanged = true;
                                                    }
                                                    else if (obj == 'No' || obj == 'Needed') {
                                                        item.chkvalue[obj] = true;
                                                        item.isChanged = false;
                                                    }
                                                }
                                            })
                                        }
                                        else if (item.controlType === "Radiobutton") {
                                            if (item.validationStatus) {
                                                if (item.controlValues == 'Yes,No') {
                                                    item.oceControlValue = 'Yes';
                                                    item.isChanged = true;
                                                }
                                                else if (item.controlValues == 'Current,Needed') {
                                                    item.oceControlValue = 'Current'
                                                    item.isChanged = true;
                                                }
                                            }
                                            else {
                                                if (item.controlValues == 'Yes,No') {
                                                    item.oceControlValue = 'No';
                                                    item.isChanged = false;
                                                }
                                                else if (item.controlValues == 'Current,Needed') {
                                                    item.oceControlValue = 'Needed'
                                                    item.isChanged = false;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }
                    else {
                        toast.error(renderToastifyMsg('Checklist questions are not available for the selected Oncall Date',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                        dispatch({
                            type: ActionType.SET_LOADING_FALSE
                        });
                        return;
                    }
                    dispatch({
                        question: question,
                        q_Index: q_Index,
                        isVisibleContent: isVisibleContent,
                        allowDateChange: allowDateChange,
                        URLOCE: URLOCE,
                        showExpand: showExpand,
                        questionURL: questionURL,
                        type: ActionType.LOADQUESTIONS_OCEVIEW_SUCCESS
                    });
                    if (url.isNew === true) {
                        CallRebindActionItems(dispatch);
                        CallOnQuestionsSave(dispatch);
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                });
        }
        else {
            Callservicechange(dispatch);
        }
    },
    // Function Used to Update the Display Order for the Questionnaire Used in Template Questionnaire Section
    UpdateChecklistQuestionDisplayOrder: (data): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let ischecklistsavebuttondisabled = getState().Checklist.ischecklistsavebuttondisabled;
        data.forEach((row, index) => {
            row.DisplayOrder = (index + 1).toString();
        });
        dispatch({
            ischecklistsavebuttondisabled,
            QuestionarrieList: data,
            type: ActionType.ADD_CHECKLIST_QUESTIONS_DISPLAYORDER_TOLIST
        });
    },

    OnQuestionsSave: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.ONQUESTIONS_SAVE_OCEVIEW_REQUEST
        });
        let question = getState().Checklist.OCEquestion;
        if (question.length === 0) {
            toast.error(renderToastifyMsg('Checklist or Template is InActive for this Date',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
            return false;
        }
        let icmMainIncident = getState().Checklist.icmMainIncident;
        var mainICMId = icmMainIncident === '' ? '1' : icmMainIncident;
        let actionitems = getState().Checklist.actionitems;
        let ChecklistQuestions = getState().Checklist.ChecklistQuestions;
        let fromDate = getState().Checklist.fromDate;
        let serviceId = getState().Checklist.serviceId;
        let checklistID = getState().Checklist.checklistID;
        let meetingId = getState().Checklist.meetingId;
        let meetingTitle = getState().Checklist.meetingTitle;
        let onCallDate = getState().Checklist.onCallDate;
        let questionincidents = getState().Checklist.questionincidents;
        let LoginuserId = getState().Checklist.LoginuserId;
        let clMeetingitems = getState().Checklist.clMeetingitems;
        let url = getState().Checklist.url;
        let allowDateChange = getState().Checklist.allowDateChange;

        //Validate Main ICM ID before save
        ChecklistAPI.ValidateIncidentId(mainICMId)
            .then((response) => {
                if (response !== undefined && response !== null) {
                    if (response) {
                        actionitems = [];
                        var IsvalidationFailed = false;
                        question.map((obj: any, i) => {
                            if (question[i].controlType === 'CheckBox') {
                                if (question[i].chkvalue !== undefined && question[i].chkvalue !== '' && question[i].chkvalue !== {}) {
                                    var validdata = false;
                                    let values: any = '';
                                    Object.keys(question[i].chkvalue).map(key => {
                                        let value: any = question[i].chkvalue[key];
                                        if (values === '' && value === true) {
                                            values = key;
                                            validdata = true;
                                        }
                                        else if (value === true) {
                                            values = values + ',' + key;
                                            validdata = true;
                                        }
                                    });
                                    question[i].oceControlValue = values;
                                }
                            }
                            // If Controltype is Date then Saving the Date value in ""YYYY-MM-DD"" format in DB
                            if (question[i].controlType === 'Date') {
                                if (question[i].oceControlValue !== null && question[i].oceControlValue !== undefined && question[i].oceControlValue !== '') {
                                    question[i].oceControlValue = moment(question[i].oceControlValue).format("YYYY-MM-DD");
                                }
                            }
                            if (question[i].isChanged === true) {
                                IsvalidationFailed = true;
                            }

                            ChecklistQuestions = {};
                            if (question[i].comment !== null && question[i].comment !== "" && question[i].comment !== undefined) {
                                ChecklistQuestions.meetingItemId = 0;
                                ChecklistQuestions.meetingDate = moment(fromDate).format("MM/DD/YYYY");
                                ChecklistQuestions.title = question[i].questionName;
                                ChecklistQuestions.qualitySpends = '';
                                ChecklistQuestions.actionItems = question[i].comment;
                                ChecklistQuestions.participants = '';
                                ChecklistQuestions.serviceId = serviceId;
                                ChecklistQuestions.meetingStatus = '';
                                ChecklistQuestions.owner = LoginuserId;
                                ChecklistQuestions.createdBy = LoginuserId;
                                ChecklistQuestions.questionId = question[i].questionId;
                                ChecklistQuestions.incidentIds = null;
                                ChecklistQuestions.meetingId = meetingId;
                                actionitems.push(ChecklistQuestions);
                            }
                        });
                        clMeetingitems.map((row, key) => {
                            if (row.questionId === 0) {
                                actionitems.push(row);
                            }
                        });
                        meetingTitle = (meetingTitle === undefined || meetingTitle === null) ? '' : meetingTitle;
                        icmMainIncident = (icmMainIncident === undefined || icmMainIncident === null) ? '' : icmMainIncident;
                        questionincidents = actionitems.slice();
                        var obj = { 'objChecklistAnswers': question, 'objActionitems': questionincidents };
                        ChecklistAPI.InsertChecklistAnswersForOCE(obj, serviceId, checklistID, LoginuserId, moment(fromDate).format("MM/DD/YYYY"), moment(onCallDate).format("MM/DD/YYYY"), meetingId, meetingTitle, icmMainIncident)
                            .then((meetingIdsaved: number) => {
                                if (meetingIdsaved !== undefined && meetingIdsaved !== null) {
                                    var newMeetingId = meetingIdsaved;
                                    if (url.isNew) {
                                        meetingId = newMeetingId;
                                        url.isNew = false;
                                        allowDateChange = true;
                                        let newurl = window.location.origin + "/UIforOCEView?SId=" + serviceId + "&CId=" + checklistID + "&Md=" + moment(fromDate).format("MM/DD/YYYY") + "&OnCalld=" + moment(onCallDate).format("MM/DD/YYYY") + "&meetingId=" + meetingId + "&meetingTitle=" + meetingTitle + "&icmMainIncident=" + icmMainIncident + "&IsNew=false";
                                        window.history.pushState({ path: newurl }, '', newurl);
                                    }
                                    else {
                                        toast.success(renderToastifyMsg('Data saved successfully',
                                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                                        // After Save instead of redirecting to OCEList perform refresh of data
                                        CallLoad_Data_OCEView(dispatch, 0);
                                        CallRowCancel(-1, dispatch);
                                    }
                                }
                                dispatch({
                                    meetingId: meetingId,
                                    allowDateChange: allowDateChange,
                                    url: url,
                                    type: ActionType.ONQUESTIONS_SAVE_ANSWERS_OCEVIEW_SUCCESS
                                });
                            }).catch(error => {
                                dispatch({
                                    type: ActionType.ONQUESTIONS_SAVE_ANSWERS_OCEVIEW_FAIL
                                });
                            });
                        dispatch({
                            question: question,
                            actionitems: actionitems,
                            ChecklistQuestions: ChecklistQuestions,
                            meetingTitle: meetingTitle,
                            icmMainIncident: icmMainIncident,
                            questionincidents: questionincidents,
                            type: ActionType.ONQUESTIONS_SAVE_OCEVIEW_SUCCESS
                        });
                    }
                    else {
                        toast.error(renderToastifyMsg('Main Incident ID must either be a Valid Incident or Blank.',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                        dispatch({
                            type: ActionType.SET_LOADING_FALSE
                        });
                        return false;
                    }
                }
                else {
                    toast.error(renderToastifyMsg('Error validating Main Incident ID. Please try again.',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                    return false;
                }
            }).catch(error => {
                toast.error(renderToastifyMsg('Error validating Main Incident ID. Please try again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
    },
    //To get ESTS service related questions
    servicechange: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let ests = getState().Checklist.ests;
        let filterparams = getState().Checklist.filterparams;
        let serviceId = getState().Checklist.serviceId;
        let fromDate = getState().Checklist.fromDate;
        let questionsList = getState().Checklist.questionsList;
        ests = false;
        filterparams = {};
        filterparams.serviceId = serviceId;
        filterparams.questionId = 1;
        filterparams.choiceId = null;
        filterparams.meetingDate = moment(fromDate).format("MM/DD/YYYY");
        filterparams.isFristRequest = true;

        if (filterparams.serviceId === 0) {
            ests = true;
            ChecklistAPI.GetQuestionsForESTSService(filterparams)
                .then((response: ChecklistQuestionsByChoiceForOce[]) => {
                    let question = [];
                    if (response !== null) {
                        response.map((obj: ChecklistQuestionsByChoiceForOce) => {
                            question.push(obj);
                        })
                        questionsList = question[0];
                        dispatch({
                            question: question,
                            questionsList: questionsList,
                            ests: ests,
                            filterparams: filterparams,
                            type: ActionType.SERVICECHANGE_ESTS_SERVICE_QUESTIONNAIRE
                        });
                    }
                    CallRebindActionItems(dispatch);
                    if (questionsList !== null) {
                        //To display ESTS related incidents
                        CallChecklistIncidentsForOCE(dispatch, serviceId);
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                });
        }
        else {
            CallLoadingQuestionsOCE(dispatch, 0);
        }
    },
    filterIncidentsBySeverity: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let incidentFilterSeverityModel = getState().Checklist.incidentFilterSeverityModel;
        incidentFilterSeverityModel = e.value;
        dispatch({
            incidentFilterSeverityModel: incidentFilterSeverityModel,
            type: ActionType.SET_SEVERITY_INCIDENTSPOPUP_OCEVIEW
        });
        CallgetFilteredIncidents(dispatch);
    },
    clearIncidentspopupdata: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // To Set initial array value to empty
        let filteredIncidents = [];
        let incidentFilterFromDate = moment().subtract(1, 'day').toDate();
        let incidentFilterToDate = new Date();
        dispatch({
            filteredIncidents: filteredIncidents,
            incidentFilterFromDate: incidentFilterFromDate,
            incidentFilterToDate: incidentFilterToDate,
            type: ActionType.OCEINCIDENTSPOPUP_CLEARFILTER
        });
    },
    showIncidents: (index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let filteredIncidents = [];
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let incidentFilterFromDate = moment().subtract(1, 'day').toDate();
        let incidentFilterToDate = new Date();
        let incidentFilter = {};
        let incidentFilterSeverityOptions = getState().Checklist.incidentFilterSeverityOptions;
        let incidentFilterSeverityModel = getState().Checklist.incidentFilterSeverityModel;
        let serviceId = getState().Checklist.serviceId;
        incidentFilterSeverityModel = [];
        incidentFilterSeverityModel.push(incidentFilterSeverityOptions[0], incidentFilterSeverityOptions[1]);
        //Call API to get Incidents
        CallChecklistIncidentsForOCE(dispatch, serviceId);

        let question = getState().Checklist.OCEquestion;
        let q_Index = getState().Checklist.q_Index;
        let questionURL = getState().Checklist.questionURL;
        if (question[index].controlType == 'ICMPicker') {
            if (q_Index !== index) {
                q_Index = index;
                //questionURL = $sce.trustAsResourceUrl($scope.Question[$scope.Q_Index].questionUrl.toString());
                questionURL = question[q_Index].questionUrl.toString();
                if (q_Index > index) {
                    CallForwardQuestion(dispatch);
                }
                else {
                    CallBackWardQuestion(dispatch);
                }
            }
            if (question[index].incidentIds !== undefined && question[index].incidentIds !== '') {
                question[index].isChanged = true;
            }
            else {
                question[index].isChanged = false;
            }
        }
        else if (question[index].controlType === 'ActionItems') {
            q_Index = index;
            //questionURL = $sce.trustAsResourceUrl($scope.Question[$scope.Q_Index].questionUrl.toString());
            questionURL = question[q_Index].questionUrl.toString();
            if (question[index].comment !== undefined && question[index].comment !== '') {
                question[index].isChanged = true;
            }
            else {
                question[index].isChanged = false;
            }
        }
        dispatch({
            filteredIncidents: filteredIncidents,
            incidentFilterFromDate: incidentFilterFromDate,
            incidentFilterToDate: incidentFilterToDate,
            incidentFilter: incidentFilter,
            incidentFilterSeverityModel: incidentFilterSeverityModel,
            question: question,
            questionURL: questionURL,
            q_Index: q_Index,
            type: ActionType.OCEVIEW_SHOWINCIDENTS
        });

    },
    BackWardQuestion: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let incidents = getState().Checklist.incidents;
        let question = getState().Checklist.OCEquestion;
        let q_Index = getState().Checklist.q_Index;
        let incidentIds;
        if (incidents !== undefined) {
            incidents.map((obj: any) => {
                obj.selected = false;
                if (question[q_Index].incidentIds !== undefined && question[q_Index].incidentIds !== '') {
                    incidentIds = question[q_Index].incidentIds.split(',');
                    incidentIds.map((item: any) => {
                        if (obj.incidentId == item) {
                            obj.selected = true;
                        }
                    });
                }
            })
        }
        dispatch({
            incidentIds: incidentIds,
            incidents: incidents,
            type: ActionType.OCEVIEW_BACKWARD_FORWARD_QUESTION
        });
    },
    ForwardQuestion: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let incidents = getState().Checklist.incidents;
        let question = getState().Checklist.OCEquestion;
        let q_Index = getState().Checklist.q_Index;
        let incidentIds;
        if (question.length > q_Index) {
            if (incidents != undefined) {
                incidents.map((obj: any) => {
                    obj.selected = false;
                    if (question[q_Index].incidentIds != undefined && question[q_Index].incidentIds != '') {
                        incidentIds = question[q_Index].incidentIds.split(',');
                        incidentIds.map((item: any) => {
                            if (obj.incidentId == item) {
                                obj.selected = true;
                            }
                        })

                    }
                })
            }
        }
        dispatch({
            incidentIds: incidentIds,
            incidents: incidents,
            type: ActionType.OCEVIEW_BACKWARD_FORWARD_QUESTION
        });
    },
    Expandall: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showExpand = getState().Checklist.showExpand;
        showExpand = false;
        let question = getState().Checklist.OCEquestion;
        question.map((obj: any, i) => {
            document.getElementById('head' + i).classList.value = '';
            document.getElementById('collapse' + i).classList.value = '';
            document.getElementById('head' + i).classList.add('OCEpanel-heading');
            document.getElementById('collapse' + i).classList.add('collapse', 'show');
            obj.headerClass = "OCEpanel-heading";
            obj.panelclass = "collapse show";
            obj.isExpanded = true;
        });
        dispatch({
            showExpand: showExpand,
            question: question,
            type: ActionType.OCEVIEW_EXPAND_COLLAPSE_QUESTION
        });

    },
    ColapseIcons: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showExpand = getState().Checklist.showExpand;
        showExpand = true;
        let question = getState().Checklist.OCEquestion;
        question.map((obj: any, i) => {
            document.getElementById('head' + i).classList.value = '';
            document.getElementById('collapse' + i).classList.value = '';
            document.getElementById('head' + i).classList.add('OCEpanel-heading', 'collapsed');
            document.getElementById('collapse' + i).classList.add('collapse');
            obj.headerClass = "OCEpanel-heading collapsed";
            obj.panelclass = "collapse";
            obj.isExpanded = false;
        });
        dispatch({
            showExpand: showExpand,
            question: question,
            type: ActionType.OCEVIEW_EXPAND_COLLAPSE_QUESTION
        });
    },
    //Display all action items
    RebindActionItems: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingId = getState().Checklist.meetingId;
        let fromDate = getState().Checklist.fromDate;
        let serviceId = getState().Checklist.serviceId;
        let checklistID = getState().Checklist.checklistID;
        let clMeetingitems = [];
        ChecklistAPI.GetMeetingItemsForOCE(serviceId, moment(fromDate).format("MM/DD/YYYY"), checklistID, meetingId)
            .then((meetingItemsRaw: MeetingEntityForOce[]) => {
                if (isArrayWithLength(meetingItemsRaw)) {
                    meetingItemsRaw.map((obj: MeetingEntityForOce) => {
                        clMeetingitems.push(obj);
                    })
                }
                dispatch({
                    clMeetingitems: clMeetingitems,
                    type: ActionType.GET_MEETINGITEMS_SUCCESS_OCEVIEW
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
    },
    ChecklistIncidentsForOCE: (serviceId): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_CHECKLISTINCIDENTS_OCE_REQUEST
        });
        let incidentFilterFromDate = getState().Checklist.incidentFilterFromDate;
        let incidentFilterToDate = getState().Checklist.incidentFilterToDate;
        let service = null;
        let question = getState().Checklist.OCEquestion;
        if (moment(incidentFilterFromDate).isValid() && moment(incidentFilterToDate).isValid()) {
            if (moment(incidentFilterToDate) < moment(incidentFilterFromDate)) {
                toast.error(renderToastifyMsg('To Date must be later than From Date',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
                return false;
            }
        }
        let allServices = getState().Checklist.allServices;
        allServices.map((obj: any) => {
            if (obj.value === serviceId) {
                service = obj;
            }
        })

        let isIncidents = false;
        let filteredIncidents = [];
        let incidents = getState().Checklist.incidents;
        if (service !== null) {
            ChecklistAPI.GetChecklistIncidentsForOCE(service.name, moment(incidentFilterFromDate).format("YYYY-MM-DD") + " 12:00:00 AM", moment(incidentFilterToDate).format("YYYY-MM-DD") + " 11:59:59 PM")
                .then((checklistIncidentsRaw: Incidents[]) => {
                    if (checklistIncidentsRaw !== undefined) {
                        isIncidents = true;
                        if (isArrayWithLength(checklistIncidentsRaw) && checklistIncidentsRaw !== null) {
                            var result = [];
                            checklistIncidentsRaw.map((obj: Incidents) => {
                                result.push(obj);
                            })
                            if (question !== null) {
                                incidents = result;
                                var index = 0;
                                var incidentIds = getState().Checklist.incidentIds;
                                if (incidents !== undefined) {
                                    incidents.map((item: any) => {
                                        item.selected = false;
                                        if (question[index] !== undefined && question[index].incidentIds !== undefined && question[index].incidentIds !== '') {
                                            incidentIds = question[index].incidentIds.split(',');
                                            incidentIds.map((row: any) => {
                                                if (item.incidentId === row) {
                                                    item.selected = true;
                                                }
                                            })
                                        }
                                    })
                                }
                                dispatch({
                                    incidents: incidents,
                                    incidentIds: incidentIds,
                                    type: ActionType.OCEVIEW_BACKWARD_FORWARD_QUESTION
                                });
                            }
                            filteredIncidents = incidents;
                            dispatch({
                                filteredIncidents: filteredIncidents,
                                isIncidents: isIncidents,
                                service: service,
                                type: ActionType.CHECKLIST_INCIDENTS_OCE
                            });
                            CallgetFilteredIncidents(dispatch);
                        }
                        else {
                            filteredIncidents = [];
                            dispatch({
                                filteredIncidents: filteredIncidents,
                                type: ActionType.GET_FILTERED_INCIDENTS_OCEVIEW
                            });
                        }
                    }
                    else {
                        filteredIncidents = [];
                        dispatch({
                            filteredIncidents: filteredIncidents,
                            type: ActionType.GET_FILTERED_INCIDENTS_OCEVIEW
                        });

                    }
                }).catch(error => {
                    filteredIncidents = [];
                    dispatch({
                        filteredIncidents: filteredIncidents,
                        type: ActionType.GET_CHECKLISTINCIDENTS_OCE_FAIL
                    });
                });
        }
        else {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
        }
    },
    getFilteredIncidents: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let filteredIncidents = [];
        let incidentFilterSeverityModel = getState().Checklist.incidentFilterSeverityModel;
        let q_Index = getState().Checklist.q_Index;
        let question = getState().Checklist.OCEquestion;
        let incidents = getState().Checklist.incidents;
        incidentFilterSeverityModel.map((row: any) => {
            filteredIncidents = filteredIncidents.concat(incidents.filter(item => item.severity === parseInt(row.id)));
        });
        var index = q_Index;
        if (filteredIncidents !== undefined) {
            filteredIncidents.map((obj: any) => {
                obj.selected = false;
                if (question[index] !== undefined && question[index].incidentIds !== undefined && question[index].incidentIds !== '') {
                    var incidentIds = question[index].incidentIds.split(',');
                    incidentIds.map((item: any) => {
                        if (obj.incidentId === item) {
                            obj.selected = true;
                        }
                    })
                }
            })
        }
        dispatch({
            filteredIncidents: filteredIncidents,
            type: ActionType.GET_FILTERED_INCIDENTS_OCEVIEW
        });

    },
    SaveIncidents: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let filteredIncidents = getState().Checklist.filteredIncidents;
        let incidents = getState().Checklist.incidents;
        const checked = e.target.checked;
        let filteredList = [];
        filteredIncidents.map((obj, key) => {
            if (key === index) {
                obj.selected = checked;
            }
            filteredList.push(obj);
        })
        filteredIncidents = filteredList;
        let question = getState().Checklist.OCEquestion;
        let q_Index = getState().Checklist.q_Index;
        question[q_Index].incidentIds = '';
        if (incidents !== undefined) {
            incidents.map((item, index) => {
                if (item.selected) {
                    if (index === 0) {
                        question[q_Index].incidentIds = item.incidentId.toString();
                    }
                    else {
                        question[q_Index].incidentIds = (question[q_Index].incidentIds == '') ? item.incidentId.toString() : (question[q_Index].incidentIds + ',' + item.incidentId.toString());
                    }
                }
            })
        }
        if (question[q_Index].incidentIds !== undefined && question[q_Index].incidentIds !== '') {
            question[q_Index].isChanged = true;
        }
        else {
            question[q_Index].isChanged = false;
        }
        dispatch({
            question: question,
            filteredIncidents: filteredIncidents,
            type: ActionType.SAVE_INCIDENTS_OCEVIEW
        });
    },
    chkchange: (e, index, val): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let question = getState().Checklist.OCEquestion;
        const { name, value, checked } = e.target;
        let QuestionarrieList = [];
        question.map((obj, key) => {
            if (key === index) {
                if (name === 'ActionItemComment') {
                    obj.comment = value;
                }
                else {
                    obj.chkvalue[val] = checked;
                }
            }
            QuestionarrieList.push(obj);
        })
        question = QuestionarrieList;
        let q_Index = getState().Checklist.q_Index;
        let questionURL = getState().Checklist.questionURL;
        if (q_Index !== index) {
            q_Index = index;
            //questionURL = $sce.trustAsResourceUrl($scope.Question[$scope.Q_Index].questionUrl.toString());
            questionURL = question[q_Index].questionUrl.toString();
            if (q_Index > index) {
                CallForwardQuestion(dispatch);
            }
            else {
                CallBackWardQuestion(dispatch);
            }
        }

        question.map((obj: any, key) => {
            if (question[key].dependsOn === question[index].questionName) {
                if (question[key].dependentValue === val) {
                    if (question[index].chkvalue[val] === true) {
                        question[key].color = "#FFFFFF";
                        question[key].isReadonly = false;

                    }
                    else {
                        question[key].color = "#A9A9A9";
                        question[key].isReadonly = true;
                        question[key].incidentIds = '';
                        question[key].comment = '';
                        question[key].isChanged = false;

                    }
                }
            }
        });

        var acvalues = question[index].actionItemValue.split(',');
        var ccvalues = question[index].controlValues.split(',');
        if (question[index].chkvalue !== undefined) {
            var match = false;
            var showtickmark;
            acvalues.map((item: any, indexac) => {
                ccvalues.map((obj: any, indexcc) => {
                    if (question[index].chkvalue[ccvalues[indexcc]]) {
                        showtickmark = true;
                    }
                    if (ccvalues[indexcc] === acvalues[indexac]) {
                        match = true;
                        indexcc = ccvalues.length;
                        indexac = acvalues.length;
                    }
                });
            });

            if (match) {
                question[index].showActionCheckbox = true;
                question[index].isChanged = true;
            }
            else {
                question[index].showActionCheckbox = false;
                if (showtickmark) {
                    question[index].isChanged = true;
                }
                else {
                    question[index].isChanged = false;
                }
            }
        }
        dispatch({
            question: question,
            questionURL: questionURL,
            q_Index: q_Index,
            type: ActionType.CHECKCHANGE_OCEVIEW_QUESTIONS
        });
    },
    ChangeIsExpandedCol: (index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let question = getState().Checklist.OCEquestion;
        question[index].isExpanded = !question[index].isExpanded;
        let isExpanded = getState().Checklist.isExpanded;
        let isnotExpanded = getState().Checklist.isnotExpanded;
        let showExpand = getState().Checklist.showExpand;
        isExpanded = false;
        isnotExpanded = false;
        const qlist = [];
        question.map((obj: any, key) => {
            if (key === index) {
                obj.panelclass = (obj.panelclass.indexOf("show") !== -1) ? "collapse" : "collapse show";
            }
            qlist.push(obj);
        });
        question.map((obj: any) => {
            if (obj.isExpanded === true) {
                isExpanded = true;
            }
            else {
                isnotExpanded = true;
            }
        })
        if (isExpanded === true && isnotExpanded === false) {
            showExpand = false;
        }
        else if (isExpanded === false && isnotExpanded === true) {
            showExpand = true;
        }
        else if (isExpanded === true && isnotExpanded === true) {
            showExpand = false;
        }
        dispatch({
            showExpand: showExpand,
            isExpanded: isExpanded,
            isnotExpanded: isnotExpanded,
            question: qlist,
            type: ActionType.CHANGE_EXPANDEDCOL_OCEVIEW
        });
    },
    UpdateActionItemOfTable: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let question = getState().Checklist.OCEquestion;
        const value = e.target.value;
        let QuestionarrieList = [];
        question.map((obj, key) => {
            if (key === index) {
                obj.comment = value;
            }
            QuestionarrieList.push(obj);
        })
        question = QuestionarrieList;
        let clMeetingitems = getState().Checklist.clMeetingitems;
        if (question[index].questionId !== 0) {
            clMeetingitems.map((row: any) => {
                if (row.questionID === question[index].questionId) {
                    row.actionItems = question[index].comment;
                }
            })
        }
        if (question[index].comment !== undefined && question[index].comment !== '') {
            question[index].isChanged = true;
        }
        else {
            question[index].isChanged = false;
        }

        dispatch({
            clMeetingitems: clMeetingitems,
            question: question,
            type: ActionType.UPDATE_ACTIONITEMS_OCEVIEW_QUESTIONAAIRE
        });
    },
    UpdateActionItemOfQuestion: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let question = getState().Checklist.OCEquestion;
        let clMeetingitems = getState().Checklist.clMeetingitems;
        const { value } = e.target;
        let meetingList = [];
        clMeetingitems.map((obj, key) => {
            if (key === index) {
                obj.actionItems = value;
            }
            meetingList.push(obj);
        })
        clMeetingitems = meetingList;
        if (question[index].questionId !== 0) {
            question.map((row: any) => {
                if (row.questionId === clMeetingitems[index].questionID) {
                    row.comment = clMeetingitems[index].actionItems;
                }
            })
            dispatch({
                question: question,
                clMeetingitems: clMeetingitems,
                type: ActionType.UPDATE_ACTIONITEMS_OCEVIEW_QUESTIONAAIRE
            });
        }
    },
    ActionItemInputChangedEditMode: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const { name, value } = e.target;
        let clMeetingitems = getState().Checklist.clMeetingitems;
        let meetingList = [];
        clMeetingitems.map((obj, key) => {
            if (key === index) {
                obj.title = (name === "actionItemTitle") ? value : obj.title;
                obj.qualitySpends = (name === "qualitySpends") ? value : obj.qualitySpends;
                obj.actionItemDescription = (name === "actionItemDescription") ? value : obj.actionItemDescription;
                obj.participants = (name === "participants") ? value : obj.participants;
                obj.meetingStatus = (name === "meetingStatus") ? value : obj.meetingStatus;
                obj.owner = (name === "owner") ? value : obj.owner;
            }
            meetingList.push(obj);
        })
        clMeetingitems = meetingList;
        dispatch({
            clMeetingitems: clMeetingitems,
            type: ActionType.GET_MEETINGITEMS_SUCCESS_OCEVIEW
        });
    },
    fileuplaodValidation: (event, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const files: any = Object.values(event.files);
        var invalidFiletypes = /(ade|adp|asa|ashx|asmx|asp|bas|bat|cdx|cer|chm|class|cmd|com|pbix|cnt|cpl|crt|csh|der|dll|exe|fxp|gadget|grp|hlp|hpj|hta|htr|htw|ida|idc|idq|ins|isp|its|jse|json|ksh|lnk|mad|maf|mag|mam|maq|mar|mas|mat|mau|mav|maw|mcf|mda|mdb|mde|mdt|mdw|mdz|ms-one-stub|msc|msh|msh1|msh1xml|msh2|msh2xml|mshxml|msi|msp|mst|ops|pcd|pif|pl|prf|prg|printer|ps1|ps1xml|ps2|ps2xml|psc1|psc2|pst|reg|rem|scf|scr|sct|shb|shs|shtm|shtml|sln|soap|stm|svc|url|vb|vbe|vbs|vsix|ws|wsc|wsf|wsh|xamlx)$/;
        var filename = files[files.length - 1].name;
        files.map((obj, i) => {
            var filExtn = files[i].name.substr(files[i].name.lastIndexOf('.') + 1).toLowerCase();
            if (filExtn.match(invalidFiletypes)) {
                toast.error(renderToastifyMsg('File is having an Invalid extension.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 6000 });
                return false;
            }
        })
        dispatch({
            filename: filename,
            type: ActionType.FILE_UPLOAD_FILENAME_SUCCESS
        });
    },

    fileuplaod: (event, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let meetingId = getState().Checklist.meetingId;
        let question = getState().Checklist.OCEquestion;
        let filename = getState().Checklist.filename;
        var formData = event.formData;
        formData.append("meetingId", meetingId);
        //ChecklistAPI.UploadFile(meetingId, formData)
        //    .then((response) => {
        //        if (response) {
        //            question[index].oceControlValue = filename;
        //            question[index].filePath = 'File Uploaded.';
        //            dispatch({
        //                question: question,
        //                type: ActionType.UPDATE_QUESTION_ACTIONITEM
        //            });
        //            CallIsQuestionChanged(dispatch, event, 'FileUpload', index);
        //            toast.success(renderToastifyMsg('File Uploaded.',
        //                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
        //        }
        //        else {
        //            toast.error(renderToastifyMsg('Error uploading the file. Please try again.',
        //                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
        //        }
        //    }).catch(error => {
        //        //handle error
        //        toast.error(renderToastifyMsg('Error uploading the file. Please try again.',
        //            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
        //    });
        Axios({
            method: 'post',
            url: 'api/Checklist/UploadFile',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(function (response) {
            //handle success
            if (response) {
                question[index].oceControlValue = filename;
                question[index].filePath = 'File Uploaded.';
                dispatch({
                    question: question,
                    type: ActionType.UPDATE_QUESTION_ACTIONITEM
                });
                CallIsQuestionChanged(dispatch, event, 'FileUpload', index);
                toast.success(renderToastifyMsg('File Uploaded.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            }
            else {
                toast.error(renderToastifyMsg('Error uploading the file. Please try again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });

            }
        }).catch(error => {
            //handle error
            toast.error(renderToastifyMsg('Error uploading the file. Please try again.',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
        });
    },
    DeleteFile: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let question = getState().Checklist.OCEquestion;
        let meetingId = getState().Checklist.meetingId;
        var fileName = question[index].oceControlValue;
        ChecklistAPI.DeleteChecklistFile(meetingId, fileName)
            .then((response) => {
                if (response) {
                    question[index].oceControlValue = "";
                    dispatch({
                        question: question,
                        type: ActionType.UPDATE_QUESTION_ACTIONITEM
                    });
                    CallIsQuestionChanged(dispatch, e, 'FileUpload', index);
                    toast.success(renderToastifyMsg('File Deleted.',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
                    // Reload the Questioonaire Section after Succesfull delete
                    //CallLoadingQuestionsOCE(dispatch, 0);

                }
                else {
                    toast.error(renderToastifyMsg('Error Deleting the file. Please try again.',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
                }

            }).catch(error => {
                toast.error(renderToastifyMsg('Error Deleting the file. Please try again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            });
    },
    downloadFile: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let question = getState().Checklist.OCEquestion;
        let meetingId = getState().Checklist.meetingId;
        var fileName = question[index].oceControlValue;
        ChecklistAPI.GetChecklistFile(meetingId, fileName)
            .then((response) => {
                //handle success
                if (response) {
                    dispatch({
                        type: ActionType.DOWNLOAD_FILENAME_SUCCESS
                    });
                    toast.success(renderToastifyMsg('File successfully downloaded in default system Download folder.',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
                }
                else {
                    toast.error(renderToastifyMsg('Error downloading the file. Please try again.',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
                }
            }).catch(error => {
                toast.error(renderToastifyMsg('Error downloading the file. Please try again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            });
    },

    //downloadFile: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
    //    let question = getState().Checklist.OCEquestion;
    //    let meetingId = getState().Checklist.meetingId;
    //    var fileName = question[index].oceControlValue;
    //    var url = "api/Checklist/GetChecklistFile"
    //    const token = sessionStorage.getItem("adal.access.token.key9565d7e3-f810-428e-8670-394a2a044db1");

    //    Axios
    //        .get(url, {
    //            method: "POST",
    //            headers: {
    //                Authorization: "Bearer " + token,                    
    //                Accept: "application/json",
    //                'Content-Type': 'application/json',
    //            },
    //            responseType: 'blob',
    //            params: { meetingId, fileName }
    //        })
    //        .then(function (res) {
    //            var blob = new Blob([res.data], {
    //                type: 'application/pdf',
    //            });

    //            saveAs(blob, 'test.pdf');
    //        });
    //},


    IsQuestionChanges: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let q_Index = getState().Checklist.q_Index;
        let question = getState().Checklist.OCEquestion;
        question[q_Index].Ischanged = true;
        dispatch({
            question: question,
            type: ActionType.UPDATE_QUESTION_ACTIONITEM
        });
    },
    IsQuestionChanged: (e, controltype, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let q_Index = getState().Checklist.q_Index;
        let question = getState().Checklist.OCEquestion;
        let questionURL = getState().Checklist.questionURL;
        let QuestionarrieList = [];
        if (controltype !== 'FileUpload') {
            const { name, value, checked } = e.target;
            question.map((obj, key) => {
                if (key === index) {
                    if (controltype === 'TextBox' || controltype === 'Date' || controltype === 'Dropdown' || controltype === 'TextArea' || controltype === 'Radiobutton') {
                        if (name === 'ActionItemComment') {
                            obj.comment = value;
                        }
                        else {
                            obj.oceControlValue = value;
                        }
                    }
                }
                QuestionarrieList.push(obj);
            });
            question = QuestionarrieList;
        }
        if (q_Index !== index) {
            q_Index = index;
            //questionURL = $sce.trustAsResourceUrl(question[q_Index].questionUrl.toString());
            questionURL = question[q_Index].questionUrl.toString();
        }

        if (controltype === 'Dropdown' || controltype === 'Radiobutton') {
            question.map((obj: any, key) => {
                if (question[key].dependsOn === question[q_Index].questionName) {
                    if (question[key].dependentValue === question[q_Index].oceControlValue) {
                        question[key].color = "#FFFFFF";
                        question[key].isReadonly = false;
                    }
                    else {
                        question[key].color = "#A9A9A9";
                        question[key].isReadonly = true;
                        question[key].incidentIds = '';
                        question[key].comment = '';
                        question[key].isChanged = false;
                    }
                }
            });
        }

        question[index].oceControlValue = (question[index].oceControlValue === undefined) ? '' : question[index].oceControlValue;
        question[index].comment = (question[index].comment === undefined) ? '' : question[index].comment;

        if (controltype === 'TextBox' || controltype === 'TextArea' || controltype === 'Date' || controltype === 'FileUpload') {
            if (question[index].isActionRequired === true) {
                if (question[index].oceControlValue === '' && question[index].comment === '') {
                    question[index].isChanged = false;
                }
                else {
                    question[index].isChanged = true;
                }
            }
            else {
                if (question[index].oceControlValue === '') {
                    question[index].isChanged = false;
                }
                else {
                    question[index].isChanged = true;
                }
            }
        }
        else if (controltype === 'Dropdown' || controltype === 'Radiobutton') {
            if (question[index].isActionRequired === true && question[index].oceControlValue === question[index].actionItemValue) {
                if (question[index].oceControlValue === '' && question[index].comment === '') {
                    question[index].isChanged = false;
                }
                else {
                    question[index].isChanged = true;
                }

            }
            else {
                if (question[index].oceControlValue === '') {
                    question[index].isChanged = false;
                }
                else {
                    if (question[index].controlValues !== '') {
                        let lastNegValue = question[index].controlValues.substring(question[index].controlValues.lastIndexOf(',') + 1);
                        if (lastNegValue !== '') {
                            if (NegativeResponse.includes(lastNegValue.trim())) {
                                if (question[index].oceControlValue.trim().toLowerCase() === lastNegValue.trim().toLowerCase()) {
                                    question[index].isChanged = false;
                                }
                                else {
                                    question[index].isChanged = true;
                                }
                            }
                            else {
                                question[index].isChanged = true;
                            }
                        }
                    }
                }
            }
        }
        else if (controltype === 'ActionItems') {
            if (question[index].comment !== undefined && question[index].comment !== '') {
                question[index].isChanged = true;
            }
            else {
                question[index].isChanged = false;
            }
        }
        dispatch({
            question: question,
            q_Index: q_Index,
            questionURL: questionURL,
            type: ActionType.CONTROLTYPE_VALUES_CHANGED
        });
    },
    showIframeDataUrl: (url, event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        event.stopPropagation();
        event.preventDefault();
        let iFrameKustoObj = {};
        //let iFrameDataUrl = trustAsResourceUrl(url);
        let iFrameDataUrl = url;
        dispatch({
            iFrameKustoObj: iFrameKustoObj,
            iFrameDataUrl: iFrameDataUrl,
            type: ActionType.GET_IFRAME_DATAURL
        });
    },
    //Set Kusto Query to show in iFrame
    showIframeKustoQuery: (obj, event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        event.stopPropagation();
        event.preventDefault();
        let iFrameDataUrl = '';
        let iFrameKustoObj = obj;
        dispatch({
            iFrameKustoObj: iFrameKustoObj,
            iFrameDataUrl: iFrameDataUrl,
            type: ActionType.GET_IFRAME_DATAURL
        });
    },
    //Copy Kusto URL to clipboard
    copyKustoLinkToClipboard: (data): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let fromDate = getState().Checklist.fromDate;
        var kustoServer = data.serverName.substring(8, data.serverName.indexOf('.'));
        var drilldownQuery = data.query;
        drilldownQuery = drilldownQuery.replace('todatetime(<<datetime>>)', 'datetime(' + moment(fromDate).format("MM/DD/YYYY") + ')');
        drilldownQuery = encodeURIComponent(drilldownQuery.replace('<<datacenter>>', "'" + data.Authentication + "'"));
        copyTextToClipboard('https://aka.ms/nkwe?cluster=' + kustoServer + '&database=' + data.dbname + '&query=' + drilldownQuery);
    },
    OpenDetailedQuery: (data): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var kustoServer = data.serverName.substring(8, data.serverName.indexOf('.'));
        let fromDate = getState().Checklist.fromDate;
        var drilldownQuery = data.query;
        drilldownQuery = drilldownQuery.replace('todatetime(<<datetime>>)', 'datetime(' + moment(fromDate).format("MM/DD/YYYY") + ')');
        drilldownQuery = encodeURIComponent(drilldownQuery.replace('<<datacenter>>', "'" + data.Authentication + "'"));

        window.open('https://aka.ms/nkwe?cluster=' + kustoServer + '&database=' + data.dbname + '&query=' + drilldownQuery, "_blank");

    },
    ActionItemInputChanged: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const { name, value } = e.target;
        let actionItemTitle = (name === "actionItemTitle") ? value : getState().Checklist.actionItemTitle;
        let qualitySpends = (name === "qualitySpends") ? value : getState().Checklist.qualitySpends;
        let actionItemDescription = (name === "actionItemDescription") ? value : getState().Checklist.actionItemDescription;
        let participants = (name === "participants") ? value : getState().Checklist.participants;
        let meetingStatus = (name === "meetingStatus") ? value : getState().Checklist.meetingStatus;
        let owner = (name === "owner") ? value : getState().Checklist.owner;
        dispatch({
            actionItemTitle: actionItemTitle,
            qualitySpends: qualitySpends,
            actionItemDescription: actionItemDescription,
            participants: participants,
            meetingStatus: meetingStatus,
            owner: owner,
            type: ActionType.ACTIONITEM_INPUTCHANGED_OCEVIEW
        });
    },
    AddActionItem: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let actionDataTobeSaved = getState().Checklist.actionDataTobeSaved;
        actionDataTobeSaved = true;
        dispatch({
            actionDataTobeSaved: actionDataTobeSaved,
            type: ActionType.ADD_ACTIONITEM_OCEVIEW
        });
    },
    //To edit action tems 
    RowEdit: (index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let clMeetingitems = getState().Checklist.clMeetingitems;
        const mtngslist = [];
        clMeetingitems.map((row: any, key) => {
            if (key === index) {
                row.isEdit = !row.isEdit;
            }
            mtngslist.push(row);
        });
        dispatch({
            clMeetingitems: mtngslist,
            type: ActionType.UPDATE_ACTIONITEMS_OCEVIEW
        });
    },
    //To cancel edited row
    RowCancel: (index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let actionDataTobeSaved = getState().Checklist.actionDataTobeSaved;
        let clMeetingitems = getState().Checklist.clMeetingitems;
        let meetingId = getState().Checklist.meetingId;
        let actionItemTitle = getState().Checklist.actionItemTitle;
        let qualitySpends = getState().Checklist.qualitySpends;
        let actionItemDescription = getState().Checklist.actionItemDescription;
        let participants = getState().Checklist.participants;
        let meetingStatus = getState().Checklist.meetingStatus;
        let owner = getState().Checklist.owner;
        let mtngslist = [];
        if (parseInt(index) === -1) {
            actionItemTitle = '';
            qualitySpends = ''
            actionItemDescription = '';
            participants = '';
            meetingStatus = '';
            owner = '';
            mtngslist = clMeetingitems;
        }
        else {
            clMeetingitems.map((row: any, key) => {
                if (key === index) {
                    row.isEdit = !row.isEdit;
                }
                mtngslist.push(row);
            });
            if (meetingId !== -1) {
                CallRebindActionItems(dispatch);
            }
        }
        actionDataTobeSaved = false;
        dispatch({
            actionDataTobeSaved: actionDataTobeSaved,
            clMeetingitems: mtngslist,
            actionItemTitle: actionItemTitle,
            qualitySpends: qualitySpends,
            actionItemDescription: actionItemDescription,
            participants: participants,
            meetingStatus: meetingStatus,
            owner: owner,
            type: ActionType.ROWCANCEL_ACTIONITEM_OCEVIEW
        });
    },
    //To update action item
    RowSave: (index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let clMeetingitems = getState().Checklist.clMeetingitems;
        let fromDate = getState().Checklist.fromDate;
        let LoginuserId = getState().Checklist.LoginuserId;
        let serviceId = getState().Checklist.serviceId;
        let checklistID = getState().Checklist.checklistID;
        let meetingId = getState().Checklist.meetingId;
        let actionItemTitle = getState().Checklist.actionItemTitle;
        let qualitySpends = getState().Checklist.qualitySpends;
        let actionItemDescription = getState().Checklist.actionItemDescription;
        let participants = getState().Checklist.participants;
        let meetingStatus = getState().Checklist.meetingStatus;
        let owner = getState().Checklist.owner;
        let incidents = getState().Checklist.incidents;
        var row: any = {};
        var dvTitleId = "dvTitle";
        var dvActionItemsId = "dvActionItems";
        if (parseInt(index) === -1) {
            row = {
                meetingItemId: 0,
                meetingDate: moment(fromDate).format("MM/DD/YYYY"),
                title: actionItemTitle,
                qualitySpends: qualitySpends,
                actionItems: actionItemDescription,
                participants: participants,
                serviceId: serviceId,
                meetingStatus: meetingStatus,
                owner: owner,
                createdBy: LoginuserId,
                questionId: 0,
                incidentIds: '',
                CheckListId: checklistID
            };
        }
        else {
            row = clMeetingitems[index];
            dvTitleId = "dvTitle" + index;
            dvActionItemsId = "dvActionItems" + index;
        }

        if ((row.title === null || row.title.trim() === "") && (row.actionItems === null || row.actionItems.trim() === "")) {
            document.getElementById(dvTitleId).classList.add('requiredactnItem');
            document.getElementById(dvActionItemsId).classList.add('requiredactnItem');
            return;
        }
        else if (row.title === null || row.title.trim() === "") {
            document.getElementById(dvTitleId).classList.add('requiredactnItem');
            document.getElementById(dvActionItemsId).classList.remove('requiredactnItem');
            return;
        }
        else if (row.actionItems === null || row.actionItems.trim() === "") {
            document.getElementById(dvTitleId).classList.remove('requiredactnItem');
            document.getElementById(dvActionItemsId).classList.add('requiredactnItem');
            return;
        }
        else {
            document.getElementById(dvTitleId).classList.remove('requiredactnItem');
            document.getElementById(dvActionItemsId).classList.remove('requiredactnItem');
        }

        let ChecklistQuestions: any = {};
        ChecklistQuestions.meetingItemId = row.meetingItemId;
        ChecklistQuestions.meetingDate = moment(fromDate).format("MM/DD/YYYY");
        ChecklistQuestions.title = row.title;
        ChecklistQuestions.qualitySpends = row.qualitySpends;
        ChecklistQuestions.actionItems = row.actionItems;
        ChecklistQuestions.participants = row.participants;
        ChecklistQuestions.serviceId = serviceId;
        ChecklistQuestions.meetingStatus = row.meetingStatus;
        ChecklistQuestions.owner = row.owner;
        ChecklistQuestions.createdBy = LoginuserId;
        ChecklistQuestions.CheckListId = checklistID;
        ChecklistQuestions.questionId = row.questionId;
        //To get selected incidentIds in UI
        var obj = incidents.filter(item => item.selected === true);

        ChecklistQuestions.IncidentIds = '';
        ChecklistQuestions.meetingId = meetingId;
        if (obj !== undefined) {
            if (obj.length > 0) {
                obj.map((row: any, i) => {
                    if (i === 0) {
                        ChecklistQuestions.incidentIds = row.incidentId;
                        row.incidentIds = row.incidentId;
                    }
                    else {
                        ChecklistQuestions.incidentIds = ChecklistQuestions.incidentIds + ',' + row.incidentId;
                        row.incidentIds = ChecklistQuestions.incidentIds + ',' + row.incidentId;
                    }
                });
            }
        }

        if (parseInt(index) === -1) {
            clMeetingitems.push(row);
            CallRowCancel(-1, dispatch);
        }
        else {
            clMeetingitems[index] = ChecklistQuestions;
            clMeetingitems[index].IsEdit = false;
        }
        if (meetingId !== -1) {
            ChecklistAPI.InsertUpdateChecklistActionItemsForOCE(ChecklistQuestions)
                .then((responseData: any) => {
                    if (responseData !== undefined) {
                        clMeetingitems = responseData;
                        dispatch({
                            clMeetingitems: clMeetingitems,
                            type: ActionType.UPDATE_ACTIONITEMS_OCEVIEW
                        });
                        CallRebindActionItems(dispatch);
                    }
                });
        }
    },
    MoveToArchive: (CheckListID): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.MOVETOARCHIVE
        });
        ChecklistAPI.MoveCheckListToArchive(CheckListID)
            .then((isSuccess: number) => {
                toast.success(renderToastifyMsg('Archived Successfully. Loading Data Please Wait',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                callGetOnCallDashboardChecklistData(dispatch)
            }).catch(error => {
                toast.error(renderToastifyMsg('Archived Process Failed. Please Try Again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                dispatch({
                    type: ActionType.MOVETOARCHIVE_FAIL
                });
            });
    },
};
const ValidateQuestionnaire = (checklist, QuestionnarieList, InvalidQuestionList) => {
    let hasValidationErrors = false;
    let invQlist = [];
    let cnt = 0;
    let result = { invQlist: [], Disbalebutton: false };
    // Modified the below condition from isNullOrUndefined to length = 0 as the service Dropdown control has been changed to Multiselect.
    if (checklist.selectedService.length === 0) {
        hasValidationErrors = true;
        result.Disbalebutton = true;
        return result;
    }
    else if (isNullOrUndefined(checklist.checkListName)) {
        hasValidationErrors = true;
        result.Disbalebutton = true;
        return result;
    }
    else if (isNullOrUndefined(checklist.notes)) {
        hasValidationErrors = true;
        result.Disbalebutton = true;
        return result;
    }
    //first record id is -1 when new record added so below condition to check that.
    else if (QuestionnarieList[0] != undefined && QuestionnarieList[0] != null) {
        if (QuestionnarieList[0].id != undefined && QuestionnarieList[0].id == -1 && QuestionnarieList.length == 1) {
            hasValidationErrors = true;
            result.Disbalebutton = true;
            return result;
        }
    }
    else if (QuestionnarieList.length > 0) {
        //Re-Initialize List indexes of Invalid Questions for each validation parse

        var isQuestionnaireInvalid = false;

        //first record id is -1 when new record added so below condition to check that.
        if (QuestionnarieList[0].id == undefined) {
            cnt = 0;
        }
        else if (QuestionnarieList[0].id != undefined && QuestionnarieList[0].id == -1 && QuestionnarieList.length > 1) {
            cnt = 1;
        }

        for (var i = cnt; i < QuestionnarieList.length; i++) {
            if (QuestionnarieList[i].Question == undefined || QuestionnarieList[i].Question == "") {
                isQuestionnaireInvalid = true;
                result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
            }
            else if (QuestionnarieList[i].ControlType == undefined || QuestionnarieList[i].ControlType == 0) {
                isQuestionnaireInvalid = true;
                result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
            }
            //else if (QuestionnarieList[i].DisplayOrder == undefined || QuestionnarieList[i].DisplayOrder == 0 || QuestionnarieList[i].DisplayOrder == "") {
            //    isQuestionnaireInvalid = true;
            //    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
            //}
            else if (QuestionnarieList[i].ControlType == "CheckBox" || QuestionnarieList[i].ControlType == "Radiobutton" || QuestionnarieList[i].ControlType == "Dropdown") {
                if (QuestionnarieList[i].ControlValues == undefined || QuestionnarieList[i].ControlValues == 0 || QuestionnarieList[i].ControlValues == "") {
                    isQuestionnaireInvalid = true;
                    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                }
            }
            //Disable 'Save' button when 'DependsOn' dropdown selected is INVALID
            else if (QuestionnarieList[i].DependsOn == undefined || QuestionnarieList[i].DependsOn == "") {
                isQuestionnaireInvalid = true;
                result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
            }
            //If 'DependsOn' dropdown selected is VALID
            else if (QuestionnarieList[i].DependsOn != "No") {
                //Disable 'Save' button when 'DependsOnValue' dropdown selected is invalid
                if (QuestionnarieList[i].DependsOnValue == undefined || QuestionnarieList[i].DependsOnValue == "") {
                    isQuestionnaireInvalid = true;
                    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                }
            }
            //if (isQuestionnaireInvalid == false) {
            if (QuestionnarieList[i].IsActionRequired == true) {
                if ((QuestionnarieList[i].ControlType == "CheckBox" || QuestionnarieList[i].ControlType == "Radiobutton" || QuestionnarieList[i].ControlType == "Dropdown") && QuestionnarieList[i].ControlValues != '') {
                    if (QuestionnarieList[i].ActionItemValue == undefined || QuestionnarieList[i].ActionItemValue == 0 || QuestionnarieList[i].ActionItemValue == "") {
                        isQuestionnaireInvalid = true;
                        result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                    }
                }
            }
            //}
            //if (isQuestionnaireInvalid == false) {
            if (QuestionnarieList[i].SourceType == undefined || QuestionnarieList[i].SourceType == 0 || QuestionnarieList[i].SourceType == "") {
                isQuestionnaireInvalid = true;
                result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
            }
            else if (QuestionnarieList[i].SourceType == 'Kusto') {
                if (QuestionnarieList[i].ServerName == undefined || QuestionnarieList[i].ServerName == 0 || QuestionnarieList[i].ServerName == "") {
                    isQuestionnaireInvalid = true;
                    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                }
                else if (QuestionnarieList[i].Database == undefined || QuestionnarieList[i].Database == 0 || QuestionnarieList[i].Database == "") {
                    isQuestionnaireInvalid = true;
                    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                }
                else if (QuestionnarieList[i].Query == undefined || QuestionnarieList[i].Query == 0 || QuestionnarieList[i].Query == "") {
                    isQuestionnaireInvalid = true;
                    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                }
                else if (QuestionnarieList[i].AuthenticationType == undefined || QuestionnarieList[i].AuthenticationType == 0 || QuestionnarieList[i].AuthenticationType == "") {
                    isQuestionnaireInvalid = true;
                    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                }
            }
            else if (QuestionnarieList[i].SourceType == 'URL') {
                if (QuestionnarieList[i].DataUrl == undefined || QuestionnarieList[i].DataUrl == 0 || QuestionnarieList[i].DataUrl == '') {
                    isQuestionnaireInvalid = true;
                    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                }
                else if (!ValidateURL(QuestionnarieList[i].DataUrl)) {
                    isQuestionnaireInvalid = true;
                    result.invQlist = AddIndexToInvalidQuestionList(i, result.invQlist);
                }
                //}
            }
        }
        if (isQuestionnaireInvalid) {
            hasValidationErrors = true;
            result.Disbalebutton = true;
        }
        else {
            result.Disbalebutton = false;
        }

        return result;
    }
    else if (!isArrayWithLength(QuestionnarieList)) {
        hasValidationErrors = true;
        result.Disbalebutton = true;
        return result;
    }
    result.Disbalebutton = false;
    return result;
}
//List indexes of all affected(Invalid) Questions to be highlighted in the Questionnaire table for user attention
const AddIndexToInvalidQuestionList = (invalidQuestionIndex, InvalidQuestionList) => {
    if (InvalidQuestionList.length == 0) {
        //Notification.error({ message: "Questions affected by your changes have been highlighted for your attention.", delay: 7000 });
        InvalidQuestionList.push(invalidQuestionIndex)
    }
    else if (InvalidQuestionList.length > 0 && !InvalidQuestionList.includes(invalidQuestionIndex)) {
        InvalidQuestionList.push(invalidQuestionIndex)
    }
    return InvalidQuestionList;
}
const Checknewquestiondata = (question) => {

    if (isNullOrUndefined(question.question)) {
        return true;
    }
    else if (isNullOrUndefined(question.DependsOn)) {
        return true;
    }
    else if (isNullOrUndefined(question.ControlType)) {
        return true;
    }
    if (question.DependsOn != 'No' && !isNullOrUndefined(question.DependsOn)) {
        if (isNullOrUndefined(question.DependsOnValue)) {
            return true;
        }
    }
    //if (isNullOrUndefined(question.displayOrder)) {
    //    return true;
    //}
    if (isNullOrUndefined(question.sourceType)) {
        return true;
    }
    if (question.sourceType === 'Kusto') {
        if (isNullOrUndefined(question.serverName)) {
            return true;
        }
        if (isNullOrUndefined(question.Database)) {
            return true;
        }
        if (isNullOrUndefined(question.Query)) {
            return true;
        }
        if (isNullOrUndefined(question.kustoauthenticationtype)) {
            return true;
        }
    }
    else if (question.sourceType === 'URL') {
        if (isNullOrUndefined(question.dataurl)) {
            return true;
        }
        else if (!ValidateURL(question.dataurl)) {
            return true;
        }
    }
    if ((question.ControlType === "CheckBox" || question.ControlType === "Radiobutton" || question.ControlType === "Dropdown")) {
        if (isNullOrUndefined(question.controlValue)) {
            return true;
        }
    }
    if (isNullOrUndefined(question.dynamicValidation)) {
        return true;
    }
    return false;
}

const ValidateURL = (url) => {
    var valid = false;
    var regex = /^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    var m;
    m = regex.exec(url);
    if (m !== null) {
        valid = true;
    }
    return valid;
}
// function to call GetChecklistSforOCEView action method
const CallGetChecklistSforOCEView = (dispatch) => {
    dispatch(actionCreators.GetChecklistSforOCEView());
}

// function to call Load_Data_OCEView action method
const CallLoad_Data_OCEView = (dispatch, val) => {
    dispatch(actionCreators.Load_Data_OCEView(val));
}

// function to call RebindActionItems action method
const CallRebindActionItems = (dispatch) => {
    dispatch(actionCreators.RebindActionItems());
}

// function to call LoadingQuestionsOCE action method
const CallLoadingQuestionsOCE = (dispatch, val) => {
    dispatch(actionCreators.LoadingQuestionsOCE(val));
}

// function to call OnQuestionsSave action method
const CallOnQuestionsSave = (dispatch) => {
    dispatch(actionCreators.OnQuestionsSave());
}

// function to call servicechange action method
const Callservicechange = (dispatch) => {
    dispatch(actionCreators.servicechange());
}

// function to call ChecklistIncidentsForOCE action method
const CallChecklistIncidentsForOCE = (dispatch, serviceId) => {
    dispatch(actionCreators.ChecklistIncidentsForOCE(serviceId));
}

// function to call BackWardQuestion action method
const CallBackWardQuestion = (dispatch) => {
    dispatch(actionCreators.BackWardQuestion());
}

// function to call ForwardQuestion action method
const CallForwardQuestion = (dispatch) => {
    dispatch(actionCreators.ForwardQuestion());
}

// function to call getFilteredIncidents action method
const CallgetFilteredIncidents = (dispatch) => {
    dispatch(actionCreators.getFilteredIncidents());
}

// function to call IsQuestionChanged action method
const CallIsQuestionChanged = (dispatch, e, controlType, index) => {
    dispatch(actionCreators.IsQuestionChanged(e, controlType, index));
}
// function to call RowCancel action method
const CallRowCancel = (index, dispatch) => {
    dispatch(actionCreators.RowCancel(index));
}

// function to call GetOnCallDashboardChecklistData action method
const callGetOnCallDashboardChecklistData = (dispatch) => {
    dispatch(actionCreators.GetOnCallDashboardChecklistData());
}
//Copy URL to clipboard; for jarvis and data URLs
const copyTextToClipboard = (text) => {
    if (text != '') {
        var textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toast.success(renderToastifyMsg('Link copied to clipboard',
            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
    }
};

//common function to check null , undefined and empty values
const Checknulls = (x) => {
    if (x == '' || x == undefined || x == null) {
        return true;
    }
    else
        return false;
}

const chkchangewhileloading = (question, index, values) => {
    var acvalues = question[index].actionItemValue.split(',');
    var ccvalues = values;
    if (question[index].chkvalue != undefined) {
        var match = false;
        acvalues.map((item: any, indexac) => {
            ccvalues.map((obj: any, indexcc) => {
                if (ccvalues[indexcc] == acvalues[indexac]) {
                    match = true;
                    indexcc = ccvalues.length;
                    indexac = acvalues.length;
                }
            });
        });

        if (match) {
            question[index].showActionCheckbox = true;
        }
        else {
            question[index].showActionCheckbox = false;
        }
    }
    return question;
}

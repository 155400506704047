import axios, { AxiosInstance } from 'axios';
import { getToken, authenticateUser } from '../adalConfig';
import { UserInfo } from 'react-adal';
import { isNullOrUndefined } from '../utils';

/**
 * Service API base class - configures default settings/error handling for inheriting class
 */
export abstract class BaseService {
  protected readonly $http: AxiosInstance;

    protected constructor(controllerName: string, requestTimeout: number = 500000) {

        let sbaseURL;
        let appHost = window.location.host;
        let protocol = window.location.protocol;
        if (!isNullOrUndefined(appHost)) {
            sbaseURL = `${protocol}//${appHost}/api/${controllerName}/`;
        }

        const userAuth: UserInfo | false = authenticateUser();

        this.$http = axios.create({
            timeout: requestTimeout,
            baseURL: sbaseURL,
            headers: { Authorization: `Bearer ${getToken()}` }
        });
    }
}
